<template>
    <div>
        <truncated v-if="fullView" disabled class="mb-4">
            {{ data.postText }}
        </truncated>

        <truncated v-else class="mb-4">
            {{ data.text }}

            <template #moreButton>
                <a href="#" class="link text-sm" @click.prevent="openModal">Read more</a>
            </template>
        </truncated>

        <div class="relative">
            <audio class="block w-full focus:outline-none" controls>
                <source :type="audio.mimeType" :src="audio.url">
            </audio>
        </div>
    </div>
</template>

<script>
import Truncated from '@vue-components/Truncated.vue';

export default {
    components: {
        Truncated,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },

        fullView: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        audio() {
            return this.data.audio[0] || null;
        },
    },

    methods: {
        openModal() {
            this.$events.emit(`post.detail.${this.data.id}`);
        },
    },
};

</script>
