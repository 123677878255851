<template>
    <span class="font-medium ml-4 text-xs">
        <slot></slot>
    </span>
</template>

<script>

export default {

};

</script>
