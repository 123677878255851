<template>
    <slot :on-new-post="onNewPost"></slot>

    <div v-infinite-scroll="onLoadMore" infinite-scroll-disabled="disableLazyLoad" infinite-scroll-distance="100">
        <post v-for="post in posts" :key="post.uid" :data="post" @delete-post="onDeletePost" />

        <div v-if="$apollo.queries.posts.loading" class="text-center">
            <loading class="mx-auto" />
        </div>

        <div v-else-if="posts.length === 0" class="p-8 bg-white border border-gray-100 rounded-lg">
            <p>Welcome to Earthmob! Create a new post or follow someone in your <a href="/feed" class="link">feed</a>.</p>
        </div>
    </div>
</template>

<script>
import { gql } from 'graphql-tag';

import { GET_POSTS_QUERY } from '@graphql/Posts';
import Post from '@vue-components/posts/Post.vue';
import Loading from '@vue-components/Loading.vue';
import InfiniteScroll from '@vue-directives/InfiniteScroll';

export default {
    name: 'Posts',

    components: {
        Post,
        Loading,
    },

    directives: {
        InfiniteScroll,
    },

    props: {
        author: {
            type: [String, Number],
            default: null,
        },

        entry: {
            type: [String, Number],
            default: null,
        },

        topics: {
            type: Array,
            default: null,
        },

        tag: {
            type: [String, Number],
            default: null,
        },
    },

    data() {
        return {
            posts: [],
            limit: 10,
            page: 0,
            noMore: false,
            skipQuery: true,
        };
    },

    computed: {
        disableLazyLoad() {
            return this.noMore || this.$apollo.queries.posts.loading;
        },
    },

    mounted() {
        // Prevent the query running multiple times
        this.skipQuery = false;

        this.$events.on('feed.deletePost', this.onDeletePost);
    },

    beforeUnmount() {
        this.$events.off('feed.deletePost', this.onDeletePost);
    },

    apollo: {
        posts: {
            query: GET_POSTS_QUERY,

            variables() {
                return {
                    topics: this.topics,
                    tags: this.tag ? [this.tag] : null,
                    author: this.author ? [this.author] : null,
                    entry: this.entry ? [this.entry] : null,
                    limit: this.limit,
                    offset: 0,
                };
            },

            skip() {
                return this.skipQuery;
            },
        },
    },

    methods: {
        onNewPost() {
            this.page = 0;
            this.$apollo.queries.posts.refetch();
        },

        onLoadMore() {
            this.page++;

            const offset = this.page * this.limit;

            this.$apollo.queries.posts.fetchMore({
                query: GET_POSTS_QUERY,

                variables: {
                    topics: this.topics,
                    tags: this.tag ? [this.tag] : null,
                    author: this.author ? [this.author] : null,
                    entry: this.entry ? [this.entry] : null,
                    limit: this.limit,
                    offset,
                },

                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const posts = [...(previousResult?.posts ?? []), ...fetchMoreResult.posts];

                    this.$nextTick(() => {
                        this.noMore = fetchMoreResult.posts.length < this.limit;
                    });

                    return {
                        posts,
                    };
                },
            });
            // Keep this for when we figure out to how to make caching work with Apollo
            // so we aren't relying on the deprecated `updateQuery()`. We can then ditch `updateQuery()` entirely
            // .then((fetchMoreResult) => {
            // Update the variable to keep track of whether to keep infinite-scroll
            // this.noMore = fetchMoreResult.data.posts.length < this.limit;
            // });
        },

        onDeletePost(entryId) {
            this.posts = this.posts.filter((post) => { return post.id !== entryId; });

            // Figure out a better way to refresh the cache?
            this.$apollo.queries.posts.refetch();
        },
    },
};
</script>
