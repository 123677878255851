<template>
    <form class="block w-full" @submit.prevent="onSubmit">
        <div class="flex items-start">
            <post-author v-model="post.authorId" class="mr-2" />

            <div class="flex-1">
                <div class="relative bg-gray-100 shadow-none rounded-[1.25rem] transition-[padding-bottom]" :class="{ 'pb-12': focused || hasValue }">
                    <div v-if="loading && isMediaPost" class="absolute inset-0 flex items-center p-8 z-50 bg-white/90">
                        <div
                            class="w-full h-2 rounded-full overflow-hidden bg-gray-100"
                            role="progressbar"
                            :aria-valuenow="progress"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            <span class="block h-full bg-primary-500 transition-[width]" :style="{ width: `${progress}%` }"></span>
                        </div>
                    </div>

                    <label v-if="post.postText.trim() === ''" id="new-post" class="absolute mt-px px-4 pointer-events-none text-sm text-gray-400 top-[0.5rem] to-sm:top-[10px]">New comment</label>

                    <content-editable
                        v-model="post.postText"
                        aria-labelledby="new-post"
                        class="block w-full text-sm px-4 py-2 break-all min-h-[2.5rem] to-sm:text-[16px] focus:outline-none"
                        @focusin="onFocus"
                        @focusout="onBlur"
                        @paste="onCheckUrls"
                    />

                    <div v-if="postType === 'text' && post.postLink !== null" class="px-4 mt-2">
                        <embedded-asset :asset="post.postLink" />
                    </div>

                    <div v-else-if="postType === 'image' && imageFile !== null" class="px-4 mt-2">
                        <div class="relative">
                            <image-preview :file="imageFile" />

                            <button class="absolute top-0 right-0 m-2 w-6 h-6 bg-white text-body rounded-full shadow-lg focus:outline-none" @click.prevent="onRemoveMedia">
                                <span class="icon w-3 h-3">
                                    <IconTimes />
                                </span>
                            </button>
                        </div>
                    </div>

                    <div v-else-if="postType === 'video' && videoFile !== null" class="px-4 mt-2">
                        <div class="relative">
                            <video-preview :file="videoFile" />

                            <div v-if="errors.postVideo" class="alert alert--danger mt-2" role="alert">
                                <ul>
                                    <li v-for="error in errors.postVideo" :key="`error-${error}`">{{ error }}</li>
                                </ul>
                            </div>

                            <button class="absolute top-0 right-0 m-2 w-6 h-6 bg-white text-body rounded-full shadow-lg focus:outline-none" @click.prevent="onRemoveMedia">
                                <span class="icon w-3 h-3">
                                    <IconTimes />
                                </span>
                            </button>
                        </div>
                    </div>

                    <div v-else-if="postType === 'audio' && audioFile !== null" class="px-4 mt-2">
                        <div class="relative p-4 bg-white">
                            <audio-preview :file="audioFile" />

                            <div v-if="errors.postAudio" class="alert alert--danger mt-2" role="alert">
                                <ul>
                                    <li v-for="error in errors.postAudio" :key="`error-${error}`">{{ error }}</li>
                                </ul>
                            </div>

                            <button class="absolute top-0 right-0 m-2 w-6 h-6 bg-white text-body rounded-full shadow-lg focus:outline-none" @click.prevent="onRemoveMedia">
                                <span class="icon w-3 h-3">
                                    <IconTimes />
                                </span>
                            </button>
                        </div>
                    </div>

                    <div v-else-if="postType === 'gallery'" class="px-4 mt-2">
                        <div class="grid grid-cols-2 md:grid-cols-4">
                            <div
                                v-for="file in galleryImages"
                                :key="`gallery-${file.name}-${file.size}`"
                                class="relative aspect aspect-1x1"
                            >
                                <image-preview :file="file" class="absolute top-0 left-0 w-full h-full object-cover object-center" />

                                <button class="absolute top-0 right-0 m-2 w-6 h-6 bg-white text-body rounded-full shadow-lg focus:outline-none" @click.prevent="onRemoveGalleryImage(file)">
                                    <span class="icon w-3 h-3">
                                        <IconTimes />
                                    </span>
                                </button>
                            </div>
                        </div>

                        <button class="btn btn-secondary btn-medium block w-full mt-4" type="button" @click.prevent="onAddGalleryImage">
                            Add Images
                        </button>
                    </div>

                    <div v-if="hasValue" class="flex px-4">
                        <post-tags v-model="post.tags">
                            <choose-topic v-model="post.topic" class="relative mr-4 mt-4 z-20" />
                        </post-tags>
                    </div>

                    <div class="absolute flex inset-x-0 bottom-0 pointer-events-none opacity-0 px-2 transition-opacity" :class="{ 'pointer-events-auto opacity-100': focused || hasValue }">
                        <button type="button" class="flex items-center p-2 text-secondary-500 text-xs focus:outline-none" @click.prevent="onChooseImage">
                            <span class="icon w-3 h-3 mr-2">
                                <IconPhotoVideo />
                            </span>

                            <span>Photo/Video</span>
                        </button>

                        <button type="button" class="flex items-center p-2 text-secondary-500 text-xs focus:outline-none" @click.prevent="onChooseGallery">
                            <span class="icon w-3 h-3 mr-2">
                                <IconImages />
                            </span>

                            <span>Gallery</span>
                        </button>

                        <button type="button" class="flex items-center p-2 text-secondary-500 text-xs focus:outline-none" @click.prevent="onChooseAudio">
                            <span class="icon w-3 h-3 mr-2">
                                <IconMusic />
                            </span>

                            <span>Audio</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="hasValue" class="pl-12">
            <button
                type="submit"
                class="btn btn-primary btn-medium block w-full mt-4"
                :class="{ loading }"
                :disabled="loading"
            >
                {{ post.sourceId ? 'Save' : 'Post' }}
            </button>
        </div>

        <input
            ref="imageVideoInput"
            class="sr-only"
            type="file"
            accept=".jpg,.jpeg,.png,.mp4,.m4v,.mov"
            @change="onFileChange"
        >

        <input
            ref="galleryInput"
            class="sr-only"
            type="file"
            accept=".jpg,.jpeg,.png"
            multiple
            @change="onGalleryChange"
        >

        <input
            ref="audioInput"
            class="sr-only"
            type="file"
            accept=".mp3,.wav"
            @change="onFileChange"
        >
    </form>
</template>

<script>
import { Buffer } from 'buffer/';
import Autolinker from 'autolinker';

import ContentEditable from '@vue-components/ContentEditable.vue';
import EmbeddedAsset from '@vue-components/EmbeddedAsset.vue';
import PostAuthor from './PostAuthor.vue';
import ChooseTopic from './ChooseTopic.vue';
import PostTags from './PostTags.vue';
import ImagePreview from './ImagePreview.vue';
import VideoPreview from './VideoPreview.vue';
import AudioPreview from './AudioPreview.vue';

import IconPhotoVideo from '@/img/icons/photo-video.svg';
import IconImages from '@/img/icons/images-alt.svg';
import IconMusic from '@/img/icons/music.svg';
import IconTimes from '@/img/icons/times.svg';

const entryTypes = {
    text: '4',
    image: '5',
    video: '6',
    audio: '7',
    gallery: '8',
};

const videoExtensions = [
    'mp4',
    'm4v',
    'mov',
];

const audioExtensions = [
    'mp3',
    'wav',
];

const initialPost = {
    postText: '',
    postLink: null,
    topic: [],
    tags: [],
};

export default {
    components: {
        IconPhotoVideo,
        IconImages,
        IconMusic,
        IconTimes,

        ImagePreview,
        VideoPreview,
        AudioPreview,

        PostAuthor,
        EmbeddedAsset,
        ContentEditable,
        ChooseTopic,
        PostTags,
    },

    emits: ['posted'],

    data() {
        return {
            post: {
                authorId: this.$store.state.userId,
                ...initialPost,
            },
            postType: 'text',
            focused: false,
            imageFile: null,
            videoFile: null,
            audioFile: null,
            galleryImages: [],
            postUrl: null,
            loading: false,
            progress: 0,
            errors: {},
        };
    },

    computed: {
        hasValue() {
            if (this.postType == 'image') {
                return this.imageFile !== null;
            }

            if (this.postType == 'video') {
                return this.videoFile !== null;
            }

            if (this.postType == 'audio') {
                return this.audioFile !== null;
            }

            if (this.postType == 'gallery') {
                return this.galleryImages.length > 0;
            }

            return this.post.postText.trim() !== '';
        },

        isMediaPost() {
            return this.postType !== 'text';
        },

        userId() {
            return this.$store.state.userId;
        },
    },

    mounted() {
        this.$events.on('feed.editPost', this.onEditPost);
    },

    beforeUnmount() {
        this.$events.off('feed.editPost', this.onEditPost);
    },

    methods: {
        async onSubmit() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.errors = {};

            try {
                const params = this.getFieldData();

                const { data } = await this.$axios.post('/actions/feed/posts/save-post', params, {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        this.progress = (loaded * 100) / total;
                    },
                });

                if (data.success) {
                    this.onReset();
                    this.$emit('posted', data);
                } else {
                    this.errors = data.errors;
                }
            } catch (e) {
                console.error(e);
                alert('An error occurred while making your request. Please try again.');
            }

            this.loading = false;
            this.progress = 0;
        },

        onEditPost(post) {
            this.focused = true;
            this.postType = post.type;
            this.post.sourceId = post.id;
            this.post.authorId = post.author.id;
            this.post.postText = post.text;

            this.post.topic = post.topic.map((topic) => { return topic.id; });

            this.post.tags = post.tags.map((tag) => {
                return {
                    key: tag.id,
                    value: tag.title,
                };
            });

            if (post.linkedEntry.length > 0) {
                this.post.authorId = post.linkedEntry[0].id;
            }

            if (post.link && post.link.length > 0) {
                const [link] = post.link;

                this.post.postLink = link.embeddedAsset ? link.embeddedAsset : null;
                this.post.postLinkId = link.id;
            }

            if (this.postType == 'image') {
                if (post.image) {
                    // eslint-disable-next-line prefer-destructuring
                    this.imageFile = post.image[0];
                }
            }

            if (this.postType == 'video') {
                if (post.video) {
                    // eslint-disable-next-line prefer-destructuring
                    this.videoFile = post.video[0];
                }
            }

            if (this.postType == 'audio') {
                if (post.audio) {
                    // eslint-disable-next-line prefer-destructuring
                    this.audioFile = post.audio[0];
                }
            }

            if (this.postType == 'gallery') {
                if (post.images) {
                    this.galleryImages = post.images.map((image) => {
                        return {
                            ...image,
                            name: image.title,
                        };
                    });
                }
            }
        },

        getFieldData() {
            const formData = new FormData();

            if (this.post.sourceId) {
                formData.append('sourceId', this.post.sourceId);
            }

            formData.append('type', this.postType);
            formData.append('authorId', this.post.authorId);
            formData.append('fields[postText]', this.post.postText);

            for (const topic of this.post.topic) {
                formData.append('fields[topic][]', topic);
            }

            for (const tag of this.post.tags) {
                formData.append('fields[tags][]', tag.key);
            }

            if (this.postType == 'text') {
                if (this.post.postLink !== null) {
                    if (this.post.postLinkId) {
                        formData.append('fields[postLink][]', this.post.postLinkId);
                    } else {
                        const jsonData = JSON.stringify(this.post.postLink);
                        const base64 = Buffer.from(jsonData).toString('base64');

                        formData.append('fields[postLink][data][]', `data:application/json;base64,${base64}`);
                        formData.append('fields[postLink][filename][]', 'post-link.json');
                    }
                }
            }

            if (this.postType == 'image') {
                formData.append('fields[postImage]', '');

                this.getAssetFieldData(formData, 'postImage', this.imageFile);
            }

            if (this.postType == 'video') {
                formData.append('fields[postVideo]', '');

                this.getAssetFieldData(formData, 'postVideo', this.videoFile);
            }

            if (this.postType == 'audio') {
                formData.append('fields[postAudio]', '');

                this.getAssetFieldData(formData, 'postAudio', this.audioFile);
            }

            if (this.postType == 'gallery') {
                formData.append('fields[postGallery]', '');

                for (const [index, galleryFile] of this.galleryImages.entries()) {
                    this.getAssetFieldData(formData, 'postGallery', galleryFile);
                }
            }

            return formData;
        },

        getAssetFieldData(formData, handle, file) {
            if (file) {
                // Check if this is a new asset being uploaded
                if (file.id) {
                    formData.append(`fields[${handle}][]`, file.id);
                } else {
                    formData.append(`fields[${handle}][]`, file, file.name);
                }
            }
        },

        onFocus() {
            this.focused = true;
        },

        onBlur() {
            this.onCheckUrls();
        },

        onChooseImage() {
            this.$refs.imageVideoInput.click();
        },

        onChooseAudio() {
            this.$refs.audioInput.click();
        },

        onAddGalleryImage() {
            this.$refs.galleryInput.click();
        },

        onChooseGallery() {
            this.onAddGalleryImage();
            this.postType = 'gallery';
        },

        async onCheckUrls() {
            if (this.postType !== 'text') {
                return;
            }

            // Parse the text for any links - just URLs for the moment to generate an embedded link
            const urls = Autolinker.parse(this.post.postText, {
                urls: true,
                email: false,
                phone: false,
                hashtag: false,
                mention: false,
            });

            if (urls.length > 0) {
                // We only care about the first URL
                const { url } = urls[0];

                if (this.postUrl !== url) {
                    try {
                        const { data } = await this.$axios.get('/actions/feed/assets/get-url', {
                            params: {
                                url,
                            },
                        });

                        if (data.success) {
                            this.postUrl = url;
                            this.post.postLink = data.embeddedAsset;
                        }
                    } catch (e) {
                        console.error(`Failed to parse url: ${url}`);
                    }
                }
            } else {
                this.post.postLink = null;
                this.postUrl = null;
            }
        },

        onFileChange(event) {
            this.errors = {};

            if (event.target.files.length > 0) {
                const [file] = event.target.files;

                if (file.size > (100 * 1024 * 1024)) {
                    event.target.value = '';
                    alert('Sorry, the maximum file size is 100MB.');
                }

                const ext = file.name.split('.').pop().toLocaleLowerCase();

                // Ensure any existing media is emptied (if they haven't removed it already)
                this.onRemoveMedia();

                this.$nextTick(() => {
                    if (videoExtensions.includes(ext)) {
                        this.videoFile = file;
                        this.postType = 'video';
                    } else if (audioExtensions.includes(ext)) {
                        this.audioFile = file;
                        this.postType = 'audio';
                    } else {
                        this.imageFile = file;
                        this.postType = 'image';
                    }
                });
            }
        },

        onGalleryChange(event) {
            for (const file of event.target.files) {
                this.galleryImages.push(file);
            }

            this.$nextTick(() => {
                event.target.value = '';
            });
        },

        onRemoveGalleryImage(file) {
            const index = this.galleryImages.indexOf(file);

            if (index > -1) {
                this.galleryImages.splice(index, 1);
            }
        },

        onRemoveMedia() {
            const { imageVideoInput, galleryInput, audioInput } = this.$refs;

            imageVideoInput.value = '';
            galleryInput.value = '';
            audioInput.value = '';
            this.imageFile = null;
            this.videoFile = null;
            this.audioFile = null;
            this.galleryImages = [];
            this.postType = 'text';
        },

        onReset() {
            this.onRemoveMedia();

            this.post = {
                authorId: this.userId,
                ...initialPost,
            };

            this.focused = false;
        },
    },
};

</script>
