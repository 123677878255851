import { gql } from 'graphql-tag';

export const GET_PROFILE_QUERY = gql`
    query ($id: [QueryArgument]!) {
        profile: user(id: $id) {
            id
            uid
            email
            firstName
            lastName
            fullName
            followersCount
            
            photo {
                id
                title
                kind
                mimeType
                width
                height
                focalPoint
                hasFocalPoint
                originalUrl: url

                embeddedAsset {
                    title
                    url
                    type
                    description

                    image
                    imageWidth
                    imageHeight

                    aspectRatio
                    html
                }

                url @transform(width: 300, height: 300, immediately: false)
            }

            ... on User {
                facebook
                instagram
                twitter
                linkedin
                youtube
                introduction
                biography
                displayCampaigns
                displayOrganisations

                location {
                    parts {
                        city
                        state
                        country
                    }
                }
                
                projects(orderBy: "dateStarted DESC") {
                    ... on projects_BlockType {
                        id
                        projectTitle
                        dateStarted
                        dateFinished
                        description
                        volunteer
                    }
                }
                education(orderBy: "dateStarted DESC") {
                    ... on education_BlockType {
                        id
                        schoolName
                        fieldOfStudy
                        dateStarted
                        dateFinished
                        description
                    }
                }
                experience(orderBy: "dateStarted DESC") {
                    ... on experience_BlockType {
                        id
                        organisation
                        position
                        dateStarted
                        dateFinished
                        description
                        volunteer
                    }
                }
            }
        }
    }
`;

export const GET_PROFILE_LITE_QUERY = gql`
    query ($id: [QueryArgument]!) {
        profile: user(id: $id) {
            id
            uid
            fullName
            firstName
            lastName
            photo {
                id
                title
                kind
                mimeType
                width
                height
                focalPoint
                hasFocalPoint
                originalUrl: url

                embeddedAsset {
                    title
                    url
                    type
                    description

                    image
                    imageWidth
                    imageHeight

                    aspectRatio
                    html
                }

                url @transform(width: 80, height: 80, immediately: false)
            }
        }
    }
`;

export const GET_PROFILE_PHOTO_QUERY = gql`
    query ($id: [QueryArgument]!) {
        photo: user(id: $id) {
            id
            photo {
                id
                title
                kind
                mimeType
                width
                height
                focalPoint
                hasFocalPoint
                originalUrl: url

                embeddedAsset {
                    title
                    url
                    type
                    description

                    image
                    imageWidth
                    imageHeight

                    aspectRatio
                    html
                }

                url @transform(width: 300, height: 300, immediately: false)
            }
        }
    }
`;

export const GET_PROFILE_ORGS_QUERY = gql`
    query($userId: [QueryArgument]!) {
        organisations: entries(section: "organisations", authorisedUsers: $userId) {
            id
            uid
            title

            ... on organisations_organisations_Entry {
                organisationFeaturedImage {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transform(width: 300, height: 300, immediately: false)
                }
            }
        }
    }
`;

export const GET_PROFILE_CAMPAIGNS_QUERY = gql`
    query($userId: [QueryArgument]!) {
        campaigns: entries(section: "campaigns", authorisedUsers: $userId) {
            id
            uid
            title

            ... on campaigns_campaigns_Entry {
                campaignFeaturedImage {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transform(width: 300, height: 300, immediately: false)
                }
            }
        }
    }
`;
