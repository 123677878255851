import { setAttributes, $$ } from '@utils/dom';

import qs from 'qs';
import debounce from 'lodash-es/debounce';

class FilteredEntries {
    constructor($pane) {
        this.template = $pane.getAttribute('data-filtered-entries');
        this.$content = $pane.querySelector('[data-filtered-entries-content]');

        // Populate any existing params
        this.params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        this.search = this.params.search;

        this.$controls = $$('[data-filtered-entries-filter]', $pane);

        this.$controls.forEach(($control) => {
            $control.addEventListener('change', (e) => {
                e.preventDefault();

                this.addLoading();

                this.params[e.target.getAttribute('name')] = e.target.value;

                history.replaceState(null, '', `?${qs.stringify(this.params, { encode: false })}`);

                this.onSelectChange();
            });
        });
    }

    onSelectChange() {
        const url = `${this.template}?${qs.stringify(this.params, { encode: false })}`;

        fetch(url).then((response) => {
            return response.text();
        }).then((html) => {
            this.removeLoading();

            this.$content.innerHTML = html;
        }).catch((err) => {
            this.removeLoading();

            console.warn('Something went wrong.', err);
        });
    }

    addLoading() {
        this.$content.classList.add('opacity-25', 'pointer-events-none');
    }

    removeLoading() {
        this.$content.classList.remove('opacity-25', 'pointer-events-none');
    }
}

export default class {
    constructor(config) {
        // Wait for the DOM to be ready before proceeding
        if (typeof document !== 'undefined') {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', this.instantiateTabs.bind(this));
            } else if (window.requestAnimationFrame) {
                window.requestAnimationFrame(this.instantiateTabs.bind(this));
            } else {
                window.setTimeout(this.instantiateTabs.bind(this), 16);
            }
        }
    }

    instantiateTabs() {
        $$('[data-filtered-entries]').forEach(($pane) => {
            new FilteredEntries($pane);
        });
    }
}
