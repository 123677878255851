// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Simple and accessible modals
import 'a11y-dialog';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import Tabs from '@js-components/tabs';
import MiniMap from '@js-components/mini-map';
import FilteredEntries from '@js-components/filtered-entries';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================

import { createApp } from 'vue';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';
import { stringify } from 'qs';
import Axios from 'axios';
import { createStore } from 'vuex';
import mitt from 'mitt';

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
} from '@headlessui/vue';

import MenuFloat from '@vue-components/MenuFloat.vue';

import HeaderSearch from '@vue-components/HeaderSearch.vue';
import HeaderNotifications from '@vue-components/HeaderNotifications.vue';
import PostForm from '@vue-components/PostForm.vue';
import ProfileDatePicker from '@vue-components/ProfileDatePicker.vue';
import BlockField from '@vue-components/BlockField.vue';
import ElementSelect from '@vue-components/ElementSelect.vue';
import LittlePic from '@vue-components/comments/LittlePic.vue';
import ImageUpload from '@vue-components/ImageUpload.vue';
import PellEditor from '@vue-components/PellEditor.vue';
import Feed from '@vue-components/Feed.vue';
import FeedFilters from '@vue-components/FeedFilters.vue';
import Posts from '@vue-components/posts/Posts.vue';
import PostEntry from '@vue-components/posts/PostEntry.vue';
import NewPost from '@vue-components/posts/new/NewPost.vue';
import LocationPage from '@vue-components/LocationPage.vue';

const app = createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiMenu: Menu,
        HuiMenuButton: MenuButton,
        HuiMenuItems: MenuItems,
        HuiMenuItem: MenuItem,
        MenuFloat,

        HeaderSearch,
        HeaderNotifications,

        PostForm,
        ProfileDatePicker,
        BlockField,
        ElementSelect,
        LittlePic,
        ImageUpload,
        PellEditor,

        Feed,
        FeedFilters,

        Posts,
        PostEntry,
        NewPost,

        LocationPage,
    },

    mounted() {
        //
        // Initialise any non-Vue JS here, once the virtual DOM is ready
        //

        // A minimal responsive menu with just vanilla JS
        new MobileNav({
            enableBodyScrollLock: false,
        });

        // Minimal, accessible tabs
        new Tabs();

        // Leaflet maps
        new MiniMap();

        // Filtering capabilities for section indexes
        new FilteredEntries();
    },
});

// injected property "topics" is a ref and will be auto-unwrapped and no longer needs `.value` in the next minor release.
// To opt-in to the new behavior now, set `app.config.unwrapInjectedRef = true` (this config is temporary and will not be needed in the future.)
app.config.unwrapInjectedRef = true;

import { offsetLimitPagination } from '@apollo/client/utilities';

// Setup Apollo to work with GraphQL. Accessible via `this.$apollo` in SFCs.
app.use(createApolloProvider({
    defaultClient: new ApolloClient({
        cache: new InMemoryCache({
            // Enable this to help with cached pagination. Will only work with `fetchPolicy != network-only`
            // typePolicies: {
            //     Query: {
            //         fields: {
            //             entries: offsetLimitPagination(),
            //             notifications: offsetLimitPagination(),
            //             comments: offsetLimitPagination(),
            //         },
            //     },
            // },
        }),
        uri: '/api',
    }),
    defaultOptions: {
        $query: {
            // There's some pretty significant caching issues with Apollo, that wreak havoc on local development
            // and HMR. Not to mention it seems to always fetch from the server. The biggest issue is the lack of
            // fragment support with GQL queries, which without it is a maintenance nightmare...
            // I just don't trust it for production either. Keep an eye on Apollo updates.
            //
            // 1. We have to use `network-only` not `no-cache` - https://github.com/apollographql/apollo-client/issues/5239#issuecomment-668150864
            // 2. Fragments are missing in paginated calls - https://github.com/apollographql/apollo-client/issues/7470
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        },
    },
}));

// A **very** simple Vuex store, as we don't need it for much yet.
app.use(createStore({
    state() {
        return {
            userId: document.head.querySelector('meta[name="user-id"]').content || null,
        };
    },
}));

// Global events. Accessible via `this.$events` in SFCs.
app.config.globalProperties.$events = mitt();

// Configure Axios for REST handling. Accessible via `this.$axios` in SFCs.
app.config.globalProperties.$axios = Axios.create({
    transformRequest: [
        function(data, headers) {
            // Get the CSRF token for form requests from a meta tag in _layout
            // Note this is updated on each fetch, getting a fresh value from the node
            // due to static cache lazy-updating this value with Ajax
            const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content || '';
            headers['X-CSRF-Token'] = csrfToken;

            // If this is FormData, no need to serialize
            if (data instanceof FormData) {
                return data;
            }

            return stringify(data);
        },
    ],
});

app.mount('#app');
