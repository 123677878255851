<template>
    <div class="mb-6 last:mb-0">
        <article class="">
            <div class="p-4 bg-white border border-gray-100 rounded-lg">
                <post-header class="mb-4" :data="data" show-link />

                <section class="break-words">
                    <component :is="componentName" :data="data" />
                </section>

                <footer class="flex items-center justify-between mt-4">
                    <nav class="flex items-center">
                        <div v-if="data.tags && data.tags.length" class="flex items-center">
                            <span class="icon w-5 h-5">
                                <IconTags />
                            </span>

                            <tag v-for="tag in data.tags" :key="tag.id">
                                {{ tag.title }}
                            </tag>
                        </div>
                    </nav>

                    <like v-if="userId" />
                </footer>
            </div>
        </article>

        <post-modal v-if="modalOpen" :is-open="modalOpen" :data="data" @close="closeModal" />
    </div>
</template>

<script>
import { kebabCase } from 'lodash-es';

import IconTags from '@/img/icons/tags.svg';

import Like from '@vue-components/Like.vue';
import Tag from '@vue-components/Tag.vue';
import PostModal from './PostModal.vue';
import PostHeader from './PostHeader.vue';

import PostText from './types/PostText.vue';
import PostAudio from './types/PostAudio.vue';
import PostGallery from './types/PostGallery.vue';
import PostImage from './types/PostImage.vue';
import PostVideo from './types/PostVideo.vue';

export default {
    components: {
        IconTags,

        Tag,
        Like,
        PostModal,
        PostHeader,

        PostText,
        PostAudio,
        PostGallery,
        PostImage,
        PostVideo,
    },

    provide() {
        return {
            data: this.data,
        };
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            modalOpen: false,
        };
    },

    computed: {
        componentName() {
            return `post-${kebabCase(this.data.type)}`;
        },

        userId() {
            return this.$store.state.userId;
        },
    },

    mounted() {
        this.$events.on(`post.detail.${this.data.id}`, this.openModal);
    },

    beforeUnmount() {
        this.$events.off(`post.detail.${this.data.id}`, this.openModal);
    },

    methods: {
        openModal() {
            window.history.pushState(null, null, `/feed/${this.data.uid}`);

            this.modalOpen = true;
        },

        closeModal() {
            // Prevent routing from triggering too much
            if (!this.modalOpen) {
                return;
            }

            if (window.history.length === 0) {
                window.history.pushState(null, null, '/feed');
            } else {
                window.history.back();
            }

            this.modalOpen = false;
        },
    },
};

</script>
