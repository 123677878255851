<template>
    <header v-if="data" class="flex items-start">
        <a :href="postLinkParams">
            <figure class="block mr-4 rounded-full overflow-hidden">
                <img
                    v-if="postPhoto"
                    :src="placeholder"
                    :data-src="postPhoto"
                    :alt="postTitle"
                    class="block w-12 h-12 lazyload"
                >

                <span v-else class="block w-12 h-12 bg-gray-100"></span>
            </figure>
        </a>

        <div class="flex-1 leading-tight">
            <a :href="postLinkParams">
                <h3 class="h6">{{ postTitle }}</h3>
            </a>

            <div class="text-xs">
                <p>{{ postType }}</p>
                <time :datetime="postDateTime">{{ dateFormat(data.postDate, 'Do MMMM YYYY [at] h:mma') }}</time>
            </div>
        </div>

        <div v-if="showLink" class="flex items-center pt-2">
            <Menu v-if="isAuthor">
                <MenuFloat>
                    <MenuButton class="w-10 h-10 mr-2 focus:outline-none flex items-center justify-center">
                        <span class="icon w-4 h-5">
                            <IconEllipsisV />
                        </span>
                    </MenuButton>

                    <MenuItems class="w-56 origin-top-right bg-white divide-y divide-gray-100 rounded shadow ring-1 ring-black ring-opacity-5 focus:outline-none text-xs">
                        <MenuItem v-slot="{ active }">
                            <button class="flex items-center w-full px-3 py-3 transition-colors text-gray-900 hover:bg-gray-100" :class="{ 'bg-gray-100': active }" @click.prevent="onEdit">
                                <IconPencil class="icon w-3 h-3 mr-3" aria-hidden="true" />
                                Edit post
                            </button>
                        </MenuItem>

                        <MenuItem v-slot="{ active }">
                            <button class="flex items-center w-full px-3 py-3 transition-colors text-gray-900 hover:bg-gray-100" :class="{ 'bg-gray-100': active }" @click.prevent="onDelete">
                                <IconTrash class="icon w-3 h-3 mr-3" aria-hidden="true" />
                                Delete post
                            </button>
                        </MenuItem>

                        <MenuItem v-slot="{ active }">
                            <button class="flex items-center w-full px-3 py-3 transition-colors text-gray-900 hover:bg-gray-100" :class="{ 'bg-gray-100': active }" @click.prevent="openModal">
                                <IconEye class="icon w-3 h-3 mr-3" aria-hidden="true" />
                                View post
                            </button>
                        </MenuItem>
                    </MenuItems>
                </MenuFloat>
            </Menu>

            <button class="btn btn-secondary btn-medium" @click.prevent="openModal">
                View Post
            </button>
        </div>
    </header>
</template>

<script>
import {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
} from '@headlessui/vue';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { svgPlaceholder } from '@utils/svg';

import MenuFloat from '@vue-components/MenuFloat.vue';

import IconEllipsisV from '@/img/icons/ellipsis-v.svg';
import IconPencil from '@/img/icons/pencil.svg';
import IconTrash from '@/img/icons/trash-alt.svg';
import IconEye from '@/img/icons/eye-alt.svg';

dayjs.extend(advancedFormat);

export default {
    components: {
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        MenuFloat,

        IconEllipsisV,
        IconPencil,
        IconTrash,
        IconEye,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },

        showLink: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            placeholder: svgPlaceholder(1, 1),
        };
    },

    computed: {
        isAuthor() {
            return this.userId == this.data.author.id;
        },

        userId() {
            return this.$store.state.userId;
        },

        modalId() {
            return `modal-${this.data.id}`;
        },

        postType() {
            const [linkedEntry] = this.data.linkedEntry;

            if (linkedEntry) {
                switch (linkedEntry.__typename) {
                case 'organisations_organisations_Entry':
                    return 'Organisation';
                case 'campaigns_campaigns_Entry':
                    return 'Campaign';
                }
            }

            return 'Conservationist';
        },

        postTitle() {
            const [linkedEntry] = this.data.linkedEntry;
            if (linkedEntry) {
                return linkedEntry.title;
            }

            return this.data.author.fullName;
        },

        postPhoto() {
            const [linkedEntry] = this.data.linkedEntry;

            if (linkedEntry) {
                const [photo] = linkedEntry.photo;
                return photo ? photo.url : null;
            }

            return this.data.author.photo ? this.data.author.photo.url : null;
        },

        postDateTime() {
            return this.dateFormat(this.data.postDate, 'YYYY-MM-DD HH:mm');
        },

        postLinkParams() {
            const [linkedEntry] = this.data.linkedEntry;

            if (linkedEntry) {
                return `/${linkedEntry.uri}`;
            }

            return `/network/${this.data.author.userSlug}`;
        },
    },

    methods: {
        dateFormat(value, format) {
            return dayjs(value).format(format);
        },

        onEdit() {
            this.$events.emit('feed.editPost', this.data);

            window.scrollTo(0, 0);
        },

        openModal() {
            this.$events.emit(`post.detail.${this.data.id}`);
        },

        async onDelete() {
            try {
                if (confirm('Are you sure you want to delete this post?')) {
                    const { data } = await this.$axios.post('/actions/entries/delete-entry', {
                        entryId: this.data.id,
                    });

                    if (data.success) {
                        this.$events.emit('feed.deletePost', this.data.id);
                    }
                }
            } catch (e) {
                alert('An error occurred while making your request. Please try again.');
            }
        },
    },
};

</script>
