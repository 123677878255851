export const setAttributes = (el, attrs) => {
    Object.keys(attrs).forEach((key) => {
        return el.setAttribute(key, attrs[key]);
    });
};

export const toArray = (collection) => {
    // Convert a NodeList into an array
    return Array.prototype.slice.call(collection);
};

export const $$ = (selector, context) => {
    // Query the DOM for nodes matching the given selector, scoped to context (or the whole document)
    return toArray((context || document).querySelectorAll(selector));
};
