<template>
    <div class="flex items-start mb-4 last:mb-0">
        <a :href="linkTo">
            <little-pic :profile="data.user" small class="mr-2" />
        </a>

        <div class="relative flex-1 bg-gray-100 px-4 py-2 rounded-[1.25rem] border-2 border-transparent" :class="{ 'border-secondary-500/50': isMe }">
            <div class="text-xs font-medium">
                <a :href="linkTo">{{ data.user.fullName }}</a>
            </div>

            <div class="text-sm break-words">
                <truncated inline>{{ data.comment }}</truncated>
            </div>
        </div>
    </div>
</template>

<script>
import LittlePic from './LittlePic.vue';
import Truncated from '@vue-components/Truncated.vue';

export default {
    components: {
        Truncated,
        LittlePic,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        isMe() {
            return this.$store.state.userId == this.data.user.id;
        },

        linkTo() {
            return `/network/${this.data.user.userSlug}`;
        },
    },
};

</script>
