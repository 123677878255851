<template>
    <div class="truncated-box">
        <div class="truncated-box__text richtext">
            <p v-for="(paragraph, index) in paragraphs" :key="`p-${index}`" v-html="paragraph"></p>
        </div>

        <div v-if="!disabled && !expanded && hasMore" class="mt-1">
            <slot name="moreButton">
                <a v-if="!inline" class="link text-sm">Read more</a>

                <button v-else type="button" class="link text-sm" @click.prevent="onExpandInline">
                    Read more
                </button>
            </slot>
        </div>
    </div>
</template>

<script>
import Autolinker from 'autolinker';

export default {
    props: {
        inline: {
            type: Boolean,
            default: false,
        },

        limit: {
            type: Number,
            default: 50,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            expanded: false,
        };
    },

    computed: {
        text() {
            const [{ children }] = this.$slots.default();

            return children.trim();
        },

        hasMore() {
            if (this.inline) {
                const words = this.text.split(' ');
                return words.length > this.limit;
            }

            return /\[more]$/.test(this.text);

        },

        content() {
            if (this.inline) {
                if (this.disabled || this.expanded) {
                    return this.text;
                }

                let words = this.text.split(' ');
                if (words.length <= this.limit) {
                    return this.text;
                }

                words = words.slice(0, this.limit);
                words = words.join(' ');
                words = words.replace(/[\s\n]+$/, '');
                words += '…';

                return words;
            }

            if (this.disabled) {
                return this.text;
            }

            const text = this.text.replace(/\[more]$/, '…');

            return text;

        },

        paragraphs() {
            const autolinker = new Autolinker({});

            return this.content.split(/\n\s*\n/).map((paragraph) => {
                return autolinker.link(paragraph);
            });
        },
    },

    methods: {
        onExpandInline() {
            this.expanded = true;
        },
    },
};

</script>
