<template>
    <div>
        <truncated v-if="fullView" disabled class="mb-4">
            {{ data.postText }}
        </truncated>

        <truncated v-else class="mb-4">
            {{ data.text }}

            <template #moreButton>
                <a href="#" class="link text-sm" @click.prevent="openModal">Read more</a>
            </template>
        </truncated>

        <div class="relative" :class="{ 'aspect aspect-16x9': playbackError }">
            <video ref="video" class="block w-full focus:outline-none" :class="{ 'absolute inset-0': playbackError }" controls>
                <source ref="source" type="video/mp4" :src="video.url">
            </video>

            <div v-if="playbackError" class="absolute flex items-center inset-0 bg-black px-8">
                <img
                    :src="staticImg"
                    class="absolute block top-0 left-0 w-full h-full opacity-50"
                    alt="This video is processing and will be available shortly."
                >
                <p class="relative w-full text-center text-sm text-white mb-0 z-1">This video is processing and will be available shortly.</p>
            </div>
        </div>
    </div>
</template>

<script>
import staticImg from '@/img/static.gif';
import Truncated from '@vue-components/Truncated.vue';

export default {
    components: {
        Truncated,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },

        fullView: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        return {
            staticImg,
        };
    },

    data() {
        return {
            playbackError: false,
        };
    },

    computed: {
        video() {
            return this.data.video[0] || null;
        },
    },

    mounted() {
        const { video, source } = this.$refs;
        video.addEventListener('error', this.onVideoError);
        source.addEventListener('error', this.onVideoError);
        video.addEventListener('canplay', this.onVideoCanPlay);
        source.addEventListener('canplay', this.onVideoCanPlay);
    },

    beforeUnmount() {
        const { video, source } = this.$refs;
        video.removeEventListener('error', this.onVideoError);
        source.removeEventListener('error', this.onVideoError);
        video.removeEventListener('canplay', this.onVideoCanPlay);
        source.removeEventListener('canplay', this.onVideoCanPlay);
    },

    methods: {
        onVideoError() {
            this.playbackError = true;
        },

        onVideoCanPlay() {
            this.playbackError = false;
        },

        openModal() {
            this.$events.emit(`post.detail.${this.data.id}`);
        },
    },
};

</script>
