<template>
    <div class="relative">
        <Popover>
            <MenuFloat placement="left-end">
                <PopoverButton ref="topic" class="block px-4 py-2 text-sm cursor-pointer shadow-lg shadow-none rounded-full bg-light transition-shadow duration-100 ease-in-out focus:outline-none">
                    <span v-if="chosenTopics.length === 0" class="flex items-center">
                        <span class="icon w-4 h-4 mr-2" aria-hidden="true">
                            <IconBookmark />
                        </span>

                        <span>Choose topic</span>
                    </span>

                    <span v-else-if="chosenTopics.length === 1" class="flex items-center">
                        <span class="icon w-4 h-4 mr-2" aria-hidden="true">
                            <span v-html="chosenTopics[0]?.icon?.inline"></span>
                        </span>

                        <span>{{ chosenTopics[0]?.title }}</span>
                    </span>

                    <span v-else class="flex items-center">
                        <span>Multiple topics</span>
                    </span>
                </PopoverButton>

                <PopoverPanel class="w-56 origin-top-right bg-white divide-y divide-gray-100 rounded shadow ring-1 ring-black ring-opacity-5 focus:outline-none text-xs text-gray-900">
                    <div v-for="topic in topics" :key="topic.id" class="form-check flex items-start mx-2 mb-0 py-2">
                        <input :id="`topic-post-${topic.id}`" v-model="proxyValue" class="form-check-input mt-0" type="checkbox" :name="`post[topic][${topic.id}]`" :value="topic.id">
                        <label class="form-check-label" :for="`topic-post-${topic.id}`">{{ topic.title }}</label>
                    </div>
                </PopoverPanel>
            </MenuFloat>
        </Popover>
    </div>
</template>

<script>
import { gql } from 'graphql-tag';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

import MenuFloat from '@vue-components/MenuFloat.vue';

import IconBookmark from '@/img/icons/bookmark.svg';

export default {
    components: {
        MenuFloat,
        Popover,
        PopoverButton,
        PopoverPanel,
        IconBookmark,
    },

    props: {
        // eslint-disable-next-line vue/require-prop-types
        modelValue: {
            required: true,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            topics: [],
            focused: false,
        };
    },

    apollo: {
        topics: gql`{
            topics: categories(group: "topics", orderBy: "title") {
                id
                title

                ... on topics_Category {
                    icon: topicIcon {
                        inline
                    }
                }
            }
        }`,
    },

    computed: {
        chosenTopics() {
            if (!this.proxyValue) {
                return [];
            }

            return this.proxyValue.map((topicId) => { return this.topics.find((topic) => { return topic.id === topicId; }); });
        },

        proxyValue: {
            get() {
                return this.modelValue;
            },

            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },
};

</script>
