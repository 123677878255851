<template>
    <div class="relative">
        <little-pic :profile="chosen" />

        <label for="author-id" class="sr-only">Select author</label>

        <select id="author-id" v-model="proxyValue" class="absolute top-0 left-0 w-full h-full bg-transparent opacity-[0.01] focus:outline-none">
            <template v-for="optgroup in options">
                <optgroup v-if="optgroup.optgroup" :key="optgroup.optgroup" :label="optgroup.optgroup">
                    <option
                        v-for="option in optgroup.options"
                        :key="option.value"
                        :value="option.value"
                        v-text="option.label"
                    ></option>
                </optgroup>

                <option v-else :key="optgroup.value" :value="optgroup.value" v-text="optgroup.label"></option>
            </template>
        </select>

        <span v-if="options.length > 1" class="flex absolute bottom-0 right-0 text-xs items-center justify-center w-4 h-4 p-1 bg-white rounded-full shadow pointer-events-none">
            <IconChevronDown />
        </span>
    </div>
</template>

<script>
import IconChevronDown from '@/img/icons/chevron-down.svg';

import LittlePic from '@vue-components/comments/LittlePic.vue';

import { GET_PROFILE_CAMPAIGNS_QUERY, GET_PROFILE_LITE_QUERY, GET_PROFILE_ORGS_QUERY } from '@graphql/Profile';
import { GET_POST_ENTRIES_QUERY } from '@graphql/Posts';

export default {
    components: {
        LittlePic,
        IconChevronDown,
    },

    props: {
        modelValue: {
            type: [Number, String],
            required: true,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            profile: null,
            entries: [],
        };
    },

    apollo: {
        profile: {
            query: GET_PROFILE_LITE_QUERY,
            variables() {
                return {
                    id: this.userId,
                };
            },
        },

        entries: {
            query: GET_POST_ENTRIES_QUERY,
            variables() {
                return {
                    userId: this.userId,
                };
            },
        },
    },

    computed: {
        chosen() {
            if (!this.$apollo.queries.profile.loading && `${this.proxyValue}` === `${this.profile.id}`) {
                return this.profile;
            }

            if (!this.$apollo.queries.entries.loading) {
                const entry = this.entries.find((entry) => { return `${entry.id}` === `${this.proxyValue}`; });

                return entry ? {
                    alt: entry.title,
                    photo: entry.image,
                } : null;
            }

            return null;
        },

        options() {
            const options = [];

            if (!this.$apollo.queries.profile.loading) {
                options.push({
                    value: this.profile.id,
                    label: this.profile.fullName,
                    photo: this.profile.photo,
                });
            }

            if (!this.$apollo.queries.entries.loading) {
                const organisations = this.entries.filter((entry) => { return entry.sectionHandle === 'organisations'; });

                if (organisations.length > 0) {
                    options.push({
                        optgroup: 'Organisations',
                        options: organisations.map((organisation) => {
                            return {
                                value: organisation.id,
                                label: organisation.title,
                                photo: organisation.image.length > 0 ? organisation.image[0] : null,
                            };
                        }),
                    });
                }

                const campaigns = this.entries.filter((entry) => { return entry.sectionHandle === 'campaigns'; });

                if (campaigns.length > 0) {
                    options.push({
                        optgroup: 'Campaigns',
                        options: campaigns.map((campaign) => {
                            return {
                                value: campaign.id,
                                label: campaign.title,
                                photo: campaign.image.length > 0 ? campaign.image[0] : null,
                            };
                        }),
                    });
                }
            }

            return options;
        },

        userId() {
            return this.$store.state.userId;
        },

        proxyValue: {
            get() {
                return this.modelValue;
            },

            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },
};

</script>
