<template>
    <div
        v-click-outside="onClickOutside"
        class="search"
        :class="{
            'search--loading': $apollo.queries.users.loading,
            'search--focused': results.length && focused,
        }"
    >
        <label for="search-input" class="sr-only">Search</label>

        <input
            id="search-input"
            v-model="query"
            class="search__input"
            type="search"
            placeholder="Search"
            autocomplete="off"
            @focus="focused = true"
        >

        <span v-if="$apollo.queries.users.loading" class="loading after:border-white after:border-[1.5px] absolute right-0 align-y mr-6"></span>

        <div v-if="results.length && focused" class="search__results">
            <slot v-for="result in results" :key="result.id" :name="getSlotName(result.type)" :result="result"></slot>
        </div>
    </div>
</template>

<script>
import { gql } from 'graphql-tag';
import vClickOutside from 'click-outside-vue3';

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },

    data() {
        return {
            focused: false,
            query: '',
            users: [],
            entries: [],
        };
    },

    apollo: {
        users: {
            debounce: 300,
            query: gql`
                query ($query: String!) {
                    users(search: $query, limit: 8) {
                        searchScore
                        firstName
                        lastName
                        fullName
                        type: __typename

                        photo {
                            id
                            url @transform(width: 80, height: 80, immediately: false)
                        }

                        ... on User {
                            userSlug
                        }
                    }

                    entries(section: ["organisations", "campaigns"], search: $query, limit: 8) {
                        searchScore
                        title
                        url
                        section: sectionHandle
                        type: __typename

                        ... on organisations_organisations_Entry {
                            photo: organisationFeaturedImage {
                                id
                                url @transform(width: 80, height: 80, immediately: false)
                            }
                        }

                        ... on campaigns_campaigns_Entry {
                            photo: campaignFeaturedImage {
                                id
                                url @transform(width: 80, height: 80, immediately: false)
                            }
                        }
                    }
                }
            `,

            update({ users, entries }) {
                this.entries = entries;

                return users;
            },

            variables() {
                return {
                    query: this.query.trim(),
                };
            },

            skip() {
                return this.query.trim() === '';
            },
        },
    },

    computed: {
        results() {
            const results = [
                ...this.users,
                ...this.entries,
            ];

            results.some((a, b) => { return parseInt(a.score) - parseInt(b.score); });

            return results;
        },
    },

    watch: {
        query(newValue) {
            if (newValue.trim() === '') {
                this.users = [];
                this.entries = [];
            }
        },
    },

    methods: {
        onClickOutside() {
            this.focused = false;
        },

        getSlotName(string) {
            let name = string.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase();

            if (name.endsWith('_entry')) {
                name = 'entry';
            }

            return `result-${name}`;
        },
    },
};

</script>
