<template>
    <button type="button" :class="{ 'loading after:border-rose-500 before:bg-white before:absolute before:inset-0 before:w-full before:h-full': loading }" @click.prevent="onLike">
        <span v-if="isLiked" class="icon w-6 h-6 block text-rose-500 hover:text-rose-600 transition-colors">
            <IconHeartFill />
        </span>

        <span v-else class="icon w-6 h-6 block text-gray-300 hover:text-gray-400 transition-colors">
            <IconHeartCircle />
        </span>
    </button>
</template>

<script>
import IconHeartCircle from '@/img/icons/heart-circle-light.svg';
import IconHeartFill from '@/img/icons/heart-circle-fill.svg';

export default {
    components: {
        IconHeartCircle,
        IconHeartFill,
    },

    inject: [
        'data',
    ],

    data() {
        return {
            loading: false,
            localValue: null,
        };
    },

    computed: {
        isLiked() {
            if (this.localValue !== null) {
                return this.localValue;
            }

            if (this.data?.wishlistItem?.elementId == this.data.id) {
                return true;
            }

            return false;
        },
    },

    methods: {
        async onLike() {
            if (this.loading) {
                return;
            }

            this.loading = true;

            try {
                const { data } = await this.$axios.post('/actions/wishlist/items/toggle', {
                    elementId: this.data.id,
                });

                if (data.success) {
                    this.localValue = data.item.action === 'added';
                }
            } catch (e) {
                alert('An error occurred while making your request. Please try again.');
            }

            this.loading = false;
        },
    },
};

</script>
