// ================================================
// Tabs
// A simple, accessible and lightweight component for tabs - no fancy business!
// We refrain from using active classes/data attributes in lieu of using aria attributes, which are required
// for accessibility anyway. These can be easily targetted in CSS. No need to double-handle things.
//
// Available options:
// `tabsAttribute` | `String` | `data-tabs` | The attribute for the wrapper element surrounding tab panes and links.
// `tabLinkAttribute` | `String` | `data-tab-link` | The attribute for the clickable tab link.
// `tabPaneAttribute` | `String` | `data-tab-pane` | The attribute for the tab content shown when clicking the link.
// `autoOpenFirstTab` | `Boolean` | `true` | Whether to automatically open the first tab.
// `useHistory` | `Boolean` | `true` | Whether to update the history state to persist the tabs selected.

import { setAttributes, $$ } from '@utils/dom';

const hash = window.location.hash.replace('#', '');

class Tabs {
    constructor(tabManager, $tabs) {
        this.tabsAttribute = tabManager.tabsAttribute;
        this.tabLinkAttribute = tabManager.tabLinkAttribute;
        this.tabPaneAttribute = tabManager.tabPaneAttribute;
        this.autoOpenFirstTab = tabManager.autoOpenFirstTab;
        this.useHistory = tabManager.useHistory;

        this.$tabs = $tabs;

        this.$tabsLinks = $$(`[${this.tabLinkAttribute}]`, $tabs);
        this.$tabsPanes = $$(`[${this.tabPaneAttribute}]`, $tabs);

        this.initAccessibility();
        this.initTabs();
    }

    initAccessibility() {
        const linkIds = {};

        // Setup all tab links to have `aria-controls` and `role="tab"` attributes.
        // Also generate unique IDs used to link panes and tabs with `aria-controls`
        this.$tabsLinks.forEach(($tabLink) => {
            const paneId = $tabLink.getAttribute(`${this.tabLinkAttribute}`);

            // Save this to apply below in the pane as its `aria-labelledby`. Storing this
            // saves an expensive DOM lookup as well.
            linkIds[paneId] = `tab-link-${paneId}`;

            setAttributes($tabLink, {
                'aria-controls': paneId,
                role: 'tab',
                id: linkIds[paneId],
            });
        });

        // Setup all tab panes to have `aria-labelledby` and `role="tabpanel"` attributes.
        // Also generate unique IDs used to link panes and tabs with `aria-controls`
        this.$tabsPanes.forEach(($pane) => {
            const paneId = $pane.getAttribute(`${this.tabPaneAttribute}`);

            setAttributes($pane, {
                'aria-labelledby': linkIds[paneId],
                role: 'tabpanel',
                id: paneId,
            });
        });
    }

    initTabs() {
        this.$tabsLinks.forEach(($tab) => {
            // List to clicks on the tab links
            $tab.addEventListener('click', (e) => {
                e.preventDefault();

                this.selectTab($tab);
            });

            // Listen to keypresses on tab links
            $tab.addEventListener('keydown', (e) => {
                const index = this.$tabsLinks.indexOf($tab);
                const previousIndex = (index === 0) ? (this.$tabsLinks.length - 1) : (index - 1);
                const nextIndex = (index === this.$tabsLinks.length - 1) ? 0 : (index + 1);

                // Hit up or left = previous tab
                if ((e.keyCode === 37 || e.keyCode === 38) && !e.ctrlKey) {
                    e.preventDefault();

                    this.selectTab(this.$tabsLinks[previousIndex]);
                }

                // Hit down or right = next tab
                if ((e.keyCode === 40 || e.keyCode === 39) && !e.ctrlKey) {
                    e.preventDefault();

                    this.selectTab(this.$tabsLinks[nextIndex]);
                }
            });
        });

        // Auto-select the first tab, or the tab base on the hash
        // Find a tab with the hash in this tabs
        const $selectedTab = this.$tabs.querySelector(`#tab-link-${hash}`) || this.$tabsLinks[0];

        if (this.autoOpenFirstTab) {
            this.selectTab($selectedTab);
        }
    }

    selectTab($tab) {
        const paneId = $tab.getAttribute(`${this.tabLinkAttribute}`);
        const $pane = this.$tabs.querySelector(`[data-tab-pane="${paneId}"]`);

        // Set the attributes on the tab links
        this.$tabsLinks.forEach(($el) => {
            if ($el === $tab) {
                setAttributes($el, { 'aria-selected': true, tabindex: 0 });
            } else {
                setAttributes($el, { 'aria-selected': false, tabindex: -1 });
            }
        });

        // Set the attributes on the tab panes
        this.$tabsPanes.forEach(($el) => {
            if ($el === $pane) {
                setAttributes($el, { 'aria-hidden': false, tabindex: 0 });
            } else {
                setAttributes($el, { 'aria-hidden': true, tabindex: -1 });
            }
        });

        // Set the focus of the tab link properly
        $tab.focus();

        // Update the hash to preserve native behaviour and history
        // `setTimeout` helps to prevent some weird scroll-to focus behavior when no tab set on page load
        if (this.useHistory) {
            setTimeout(() => {
                history.pushState(null, null, `${location.pathname + location.search}#${paneId}`);
            }, 100);
        }
    }
}

export default class {
    constructor(config) {
        // Add all properties here
        this.tabsAttribute = 'data-tabs';
        this.tabLinkAttribute = 'data-tab-link';
        this.tabPaneAttribute = 'data-tab-pane';
        this.autoOpenFirstTab = true;
        this.useHistory = true;

        // Assign config from class instance to override class fields
        Object.assign(this, config);

        // Wait for the DOM to be ready before proceeding
        if (typeof document !== 'undefined') {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', this.instantiateTabs.bind(this));
            } else if (window.requestAnimationFrame) {
                window.requestAnimationFrame(this.instantiateTabs.bind(this));
            } else {
                window.setTimeout(this.instantiateTabs.bind(this), 16);
            }
        }
    }

    instantiateTabs() {
        $$('[data-tabs]').forEach(($tabs) => {
            new Tabs(this, $tabs);
        });
    }
}
