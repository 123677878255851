<template>
    <form @submit="onSubmit">
        <slot></slot>
    </form>
</template>

<script>

export default {
    methods: {
        onSubmit(event) {
            const $submitButton = this.$el.querySelector('[type="submit"]');

            if ($submitButton) {
                $submitButton.classList.add('loading');
                $submitButton.setAttribute('disabled', true);
            }
        },
    },
};

</script>
