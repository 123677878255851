<template>
    <div ref="container">
        <slot :date="date"></slot>
    </div>
</template>

<script>
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export default {
    mounted() {
        const $input1 = this.$refs.container.querySelector('[data-flatpicker-profile-input1]');
        const $input2 = this.$refs.container.querySelector('[data-flatpicker-profile-input2]');

        flatpickr($input1, {
            inline: true,
            allowInput: false,
        });

        flatpickr($input2, {
            inline: true,
            allowInput: false,
        });
    },

    methods: {
        date(value) {
            if (value && value.date) {
                value = value.date;
            }

            if (!value) {
                return '';
            }

            return dayjs(value).format('YYYY-MM-DD');
        },
    },
};

</script>
