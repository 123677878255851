<template>
    <component :is="tag" class="block overflow-hidden bg-light shadow-sm focus:outline-none" :class="{ 'bg-white': light }" v-bind="componentAttrs">
        <div v-if="embeddedAsset.image" class="border-b border-gray-100">
            <div v-if="embeddedAsset.type !== 'video'" class="aspect aspect-21x9 relative">
                <img
                    class="absolute top-0 left-0 w-full h-full object-center lazyload"
                    :class="embeddedAsset.imageHeight / embeddedAsset.imageWidth > 0.75 ? 'object-contain' : 'object-cover'"
                    :data-src="embeddedAsset.image"
                    :alt="embeddedAsset.title"
                >
            </div>

            <div v-else-if="!interactive" class="relative aspect" :style="{ 'padding-bottom': `${embeddedAsset.aspectRatio}%` }">
                <img
                    class="absolute top-0 left-0 w-full h-full object-center object-cover lazyload"
                    :data-src="embeddedAsset.image"
                    :alt="embeddedAsset.title"
                >
            </div>

            <div
                v-else
                class="relative aspect"
                :style="{ 'padding-bottom': `${embeddedAsset.aspectRatio}%` }"
                v-html="embeddedAsset.html"
            ></div>
        </div>

        <div class="p-4 text-body">
            <div v-if="embeddedAsset.type !== 'image'" class="mb-1">
                <p class="h6 text-sm">{{ embeddedAsset.title }}</p>
                <p class="text-xs">{{ embeddedAsset.description }}</p>
            </div>

            <p class="block relative text-xs h-4 text-gray-400">
                <span class="absolute inset-0 truncate">{{ embeddedAsset.url }}</span>
            </p>
        </div>
    </component>
</template>

<script>
import { isPlainObject } from 'lodash-es';

export default {
    props: {
        asset: {
            type: [Object, Array],
            required: true,
        },

        interactive: {
            type: Boolean,
            default: false,
        },

        light: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        tag() {
            return this.interactive && this.embeddedAsset.type !== 'video' ? 'a' : 'div';
        },

        componentAttrs() {
            if (this.tag !== 'a') {
                return {};
            }

            return {
                href: this.embeddedAsset.url,
                target: '_blank',
                rel: 'noopener noreferrer',
            };
        },

        firstAsset() {
            if (isPlainObject(this.asset)) {
                return this.asset;
            }

            if (this.asset.length > 0) {
                return this.asset[0];
            }

            return null;
        },

        embeddedAsset() {
            const asset = this.firstAsset;

            if (typeof asset.embeddedAsset !== 'undefined') {
                return asset.embeddedAsset;
            }

            return asset;
        },
    },
};

</script>
