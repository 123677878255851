<template>
    <img v-if="image" class="block w-full rounded overflow-hidden" :src="image">
</template>

<script>
export default {
    props: {
        // eslint-disable-next-line vue/require-prop-types
        file: {
            required: true,
        },
    },

    emits: ['loaded'],

    data() {
        return {
            image: null,
        };
    },

    mounted() {
        if (this.file.url) {
            return this.image = this.file.url;
        }

        const reader = new FileReader();

        reader.addEventListener('load', (event) => {
            this.image = event.target.result;
            this.$emit('loaded', this.image);
        });

        reader.readAsDataURL(this.file);
    },
};

</script>
