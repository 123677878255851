<template>
    <div>
        <truncated v-if="fullView" disabled>
            {{ data.postText }}
        </truncated>

        <truncated v-else>
            {{ data.text }}

            <template #moreButton>
                <a href="#" class="link text-sm" @click.prevent="openModal">Read more</a>
            </template>
        </truncated>

        <div v-if="data.link.length > 0" class="mt-2 -mx-4">
            <embedded-asset :asset="data.link" interactive />
        </div>
    </div>
</template>

<script>
import Truncated from '@vue-components/Truncated.vue';
import EmbeddedAsset from '@vue-components/EmbeddedAsset.vue';

export default {
    components: {
        EmbeddedAsset,
        Truncated,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },

        fullView: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        openModal() {
            this.$events.emit(`post.detail.${this.data.id}`);
        },
    },
};

</script>
