<template>
    <div class="relative">
        <input :name="name" type="hidden">

        <div v-if="proxyElement.id" class="result -ml-4 -mt-4">
            <input :name="`${name}[]`" :value="proxyElement.id" type="hidden">

            <slot name="element" :element="proxyElement"></slot>
        </div>

        <div v-if="!readonly" v-click-outside="blur">
            <div class="form-floating">
                <span v-if="$apollo.queries.results.loading" class="loading absolute right-0 align-y mr-6"></span>

                <input
                    :id="id"
                    v-model="query"
                    type="text"
                    class="form-control"
                    placeholder=" "
                    autocomplete="off"
                    @focus="focus"
                >

                <label class="form-label" :for="id">{{ placeholder }}</label>
            </div>

            <div v-if="results.length && focused" class="search__results">
                <button v-for="result in results" :key="result.id" :result="result" class="result" @click.prevent="onValueSelect($event, result)">
                    <slot name="element" :element="result"></slot>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { gql } from 'graphql-tag';
import vClickOutside from 'click-outside-vue3';

const GET_SEARCH_USERS_QUERY = gql`
    query ($query: String!, $limit: Int!) {
        results: users(search: $query, limit: $limit, orderBy: "score") {
            id
            fullName

            photo {
                url @transform(width: 80, height: 80, immediately: false)
            }
        }
    }
`;

const GET_SEARCH_ENTRIES_QUERY = gql`
    query ($query: String!, $limit: Int!, $section: [String]) {
        results: entries(section: $section, search: $query, limit: $limit, orderBy: "score") {
            id
            title

            ... on organisations_organisations_Entry {
                photo: organisationFeaturedImage {
                    url @transform(width: 80, height: 80, immediately: false)
                }
            }
        }
    }
`;

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        placeholder: {
            type: String,
            required: true,
        },

        elementType: {
            type: String,
            required: true,
        },

        element: {
            type: Object,
            default: () => { return {}; },
        },

        variables: {
            type: Object,
            default: () => { return {}; },
        },
    },

    data() {
        return {
            id: '',
            query: '',
            results: [],
            proxyElement: null,
            focused: false,
        };
    },

    computed: {
        readonly() {
            return this.element.id || false;
        },
    },

    watch: {
        query(newValue) {
            if (newValue.trim() === '') {
                this.results = [];
            }
        },
    },

    created() {
        this.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);

        this.proxyElement = this.element;
    },

    apollo: {
        results: {
            debounce: 500,

            query() {
                if (this.elementType == 'user') {
                    return GET_SEARCH_USERS_QUERY;
                }

                return GET_SEARCH_ENTRIES_QUERY;
            },

            variables() {
                return {
                    ...this.variables,
                    query: this.query.trim(),
                    limit: 8,
                };
            },

            skip() {
                return this.query.trim() === '';
            },
        },
    },

    methods: {
        onValueSelect(event, result) {
            this.proxyElement = result;
            this.query = '';
            this.blur(event);
        },

        focus(event) {
            this.focused = true;
        },

        blur(event) {
            this.focused = false;
        },
    },
};

</script>
