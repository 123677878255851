<template>
    <div>
        <div class="block relative p-3 bg-white border border-gray-100" :class="{ 'rounded-full': rounded, 'rounded-none border-none': editing }">
            <div
                v-if="!editing"
                :class="[
                    'relative block w-full bg-gray-100',
                    { 'rounded-full overflow-hidden': rounded },
                ]"
                :style="{ 'padding-top': `${aspectRatio * 100}%` }"
            >
                <div class="flex items-center justify-center absolute inset-0">
                    <img
                        v-if="proxyImage"
                        ref="container"
                        class="w-full h-full"
                        :src="proxyImage"
                        alt="Crop Image"
                    >

                    <slot v-else name="placeholder"></slot>
                </div>
            </div>

            <pic-editor v-else ref="editor" :file="file" :aspect-ratio="aspectRatio" />

            <div class="absolute bottom-0 inset-x-0 mb-10">
                <div v-if="!editing" class="flex justify-center">
                    <button class="flex items-center px-3 py-1 bg-white text-xs text-body border border-gray-100 cursor-pointer transition-colors focus:outline-none hover:text-secondary-500 first:rounded-l-full first:-mr-px last:rounded-r-full" type="button" @click.prevent="onChooseFile">
                        <span>Choose file</span>
                    </button>

                    <button v-if="proxyImage" class="flex items-center px-3 py-1 bg-white text-xs text-body border border-gray-100 cursor-pointer transition-colors focus:outline-none hover:text-secondary-500 first:rounded-l-full first:-mr-px last:rounded-r-full" type="button" @click.prevent="onRemove">
                        <span>Remove</span>
                    </button>
                </div>

                <div v-else class="flex justify-center">
                    <button class="flex items-center px-3 py-1 bg-white text-xs text-body border border-gray-100 cursor-pointer transition-colors focus:outline-none hover:text-secondary-500 first:rounded-l-full first:-mr-px last:rounded-r-full" type="button" @click.prevent="onApply">
                        <span>Apply</span>
                    </button>

                    <button class="flex items-center px-3 py-1 bg-white text-xs text-body border border-gray-100 cursor-pointer transition-colors focus:outline-none hover:text-secondary-500 first:rounded-l-full first:-mr-px last:rounded-r-full" type="button" @click.prevent="onCancel">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Slightly different syntax and handling for a profile photo -->
    <template v-if="profilePhoto">
        <input v-if="removeFile" type="hidden" name="deletePhoto" value="1">

        <input
            :id="id"
            ref="fileInput"
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            class="sr-only"
            :name="inputName"
            @change="onFileChange"
        >
    </template>

    <template v-else>
        <input v-if="removeFile" type="hidden" :name="inputName" value="">

        <input
            :id="id"
            ref="fileInput"
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            class="sr-only"
            :name="inputName + '[]'"
            @change="onFileChange"
        >
    </template>
</template>

<script>
import PicEditor from '@vue-components/PicEditor.vue';

export default {
    components: {
        PicEditor,
    },

    props: {
        rounded: {
            type: Boolean,
            default: true,
        },

        aspectRatio: {
            type: Number,
            default: 1,
        },

        maxImageSize: {
            type: Number,
            default: 640,
        },

        image: {
            type: String,
            default: '',
        },

        inputName: {
            type: String,
            default: '',
        },

        profilePhoto: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        const randomString = (length = 5) => { return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length); };

        return {
            id: randomString(5),
            file: null,
            editing: false,
            proxyImage: this.image,
            removeFile: false,
        };
    },

    methods: {
        onChooseFile() {
            this.$refs.fileInput.click();
        },

        onFileChange(event) {
            const { files } = event.target;

            this.editing = files.length > 0;

            if (this.editing) {
                ([this.file] = files);
            }

            this.$nextTick(() => {
                this.$refs.fileInput.value = null;
            });
        },

        updateFileInput(blob = null, uploadedFile = null) {
            // For new files uploaded (and cropped) add them to the hidden file input so they're saved when the form is
            if (blob && uploadedFile) {
                const filename = uploadedFile.name.substring(0, uploadedFile.name.lastIndexOf('.')) || uploadedFile.name;

                // Create a new file with the cropped image data. Be sure to change the file extension to JPEG which is forced on-crop
                const file = new File([blob], `${filename}.jpeg`, { type: 'image/jpeg' });

                const container = new DataTransfer();
                container.items.add(file);
                this.$refs.fileInput.files = container.files;
            }
        },

        onRemove() {
            this.file = null;
            this.editing = false;

            this.removeFile = true;

            // Reset the file input in case we'd uploaded anything
            const container = new DataTransfer();
            this.$refs.fileInput.files = container.files;

            this.proxyImage = null;
        },

        onApply() {
            const { editor, editor: { cropper } } = this.$refs;

            const canvas = cropper.getCroppedCanvas({
                maxWidth: this.maxImageSize,
                maxHeight: this.maxImageSize * this.aspectRatio,
                fillColor: '#ffffff',
            });

            // Force as JPEG to get highest quality. PNGs will be converted
            canvas.toBlob((blob) => {
                this.editing = false;
                this.removeFile = false;
                this.file = null;

                const reader = new FileReader();

                reader.addEventListener('load', (event) => {
                    this.proxyImage = event.target.result;

                    this.updateFileInput(blob, editor.file);
                });

                reader.readAsDataURL(blob);
            }, 'image/jpeg', 1);
        },

        onCancel() {
            this.file = null;
            this.editing = false;
        },
    },
};

</script>
