import { Buffer } from 'buffer/';

function svgPlaceholder(width, height) {
    const svg = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h${width}v${height}H0z" fill="none"/></svg>`;
    return `data:image/svg+xml;base64,${Buffer.from(svg).toString('base64')}`;
}

export {
    svgPlaceholder,
};
