import { gql } from 'graphql-tag';

export const GET_COMMENTS_QUERY = gql`
    query ($ownerId: [QueryArgument], $limit: Int!, $offset: Int!) {
        comments(ownerId: $ownerId, limit: $limit, offset: $offset, orderBy: "commentDate DESC") {
            id
            commentDate
            comment

            user {
                id
                uid
                fullName
                
                photo {
                    id
                    url @transform(width: 80, height: 80, immediately: false)
                }

                ... on User {
                    userSlug
                }
            }
        }
    }
`;

export const GET_COMMENT_PIC_QUERY = gql`
    query ($id: [QueryArgument]) {
        profile: user(id: $id) {
            id
            uid
            fullName
            
            photo {
                id
                url @transform(width: 80, height: 80, immediately: false)
            }

            ... on User {
                userSlug
            }
        }
    }
`;

export const CREATE_COMMENT = gql`
    mutation NewComment($ownerId: ID, $comment: String) {
        saveComment(ownerId: $ownerId, comment: $comment) {
            id
            commentDate
            comment

            user {
                id
                uid
                fullName
                
                photo {
                    id
                    url @transform(width: 80, height: 80, immediately: false)
                }

                ... on User {
                    userSlug
                }
            }
        }
    }
`;
