<template>
    <input type="hidden" :name="`fields[${handle}]`">

    <transition-group name="block-field-blocks" tag="div" class="grid grid-cols-1 gap-4">
        <div v-for="(block, index) in proxyBlocks" :key="block.id" class="block-block p-4 bg-light rounded-lg border border border-gray-200/50">
            <slot :name="getSlotName(block.type)" :block="block" :field-name="getFieldName" :field-id="getFieldId"></slot>

            <div class="btn-group text-right mt-4">
                <button type="button" class="btn btn-small btn-red" @click.prevent="onRemoveBlock(block)">Delete</button>

                <button type="button" class="btn btn-small btn-light-gray" :disabled="index === (proxyBlocks.length - 1)" @click.prevent="onMoveBlock(block, index + 1)">
                    <span class="icon w-4 h-4 -translate-y-[1px]">
                        <!-- eslint-disable-next-line -->
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-sort-down fa-w-10 fa-lg"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z" class="" /></svg>
                    </span>
                    <span class="sr-only">Move down</span>
                </button>

                <button type="button" class="btn btn-small btn-light-gray" :disabled="index === 0" @click.prevent="onMoveBlock(block, index - 1)">
                    <span class="icon w-4 h-4 translate-y-[6px]">
                        <!-- eslint-disable-next-line -->
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sort-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-sort-up fa-w-10 fa-lg" ><path fill="currentColor" d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" class="" /></svg>
                    </span>
                    <span class="sr-only">Move up</span>
                </button>
            </div>

            <input type="hidden" :name="`fields[${handle}][blocks][${block.id}][type]`" :value="block.type">
            <input type="hidden" :name="`fields[${handle}][sortOrder][]`" :value="block.id">
        </div>
    </transition-group>

    <div class="flex flex-wrap -mx-2 -mb-4 mt-4">
        <button v-for="(blockType) in blockTypes" :key="blockType" type="button" class="flex items-center text-sm text-secondary-500 mx-2 mb-4" @click.prevent="onAddBlock($event, blockType)">
            <slot :name="getSlotName(blockType, 'add-button')"></slot>
        </button>
    </div>
</template>

<script>

export default {
    props: {
        handle: {
            type: String,
            default: '',
        },

        blocks: {
            type: Array,
            default: () => { return []; },
        },

        blockTypes: {
            type: Array,
            default: () => { return []; },
        },
    },

    data() {
        return {
            proxyBlocks: this.blocks,
        };
    },

    methods: {
        onAddBlock(event, type) {
            const id = `new${this.proxyBlocks.length + 1}`;

            this.proxyBlocks.push({
                id,
                type,
            });
        },

        onRemoveBlock(block) {
            this.proxyBlocks = this.proxyBlocks.filter((testBlock) => { return testBlock !== block; });
        },

        onMoveBlock(block, position) {
            const blockIndex = this.proxyBlocks.indexOf(block);
            this.proxyBlocks.splice(blockIndex, 1);
            this.proxyBlocks.splice(position, 0, block);
        },

        getSlotName(blockType, prefix = '') {
            // Check for Super Table fields
            if (Number.isInteger(parseInt(blockType))) {
                return prefix ? prefix : 'default';
            }

            return prefix ? (`${prefix}-${blockType}`) : blockType;
        },

        getFieldName(blockId, key) {
            const parts = this.getNameParts(blockId, key);

            return `${parts.splice(0, 1)}[${parts.join('][')}]`;
        },

        getFieldId(blockId, key) {
            return this.getNameParts(blockId, key).join('-');
        },

        getNameParts(blockId, key) {
            return ['fields', this.handle, 'blocks', blockId, 'fields', ...key.split('.')];
        },
    },
};

</script>

<style lang="scss">
    .block-field-blocks-move {
        transition: transform 0.5s;
    }
</style>
