<template>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div">
            <div class="fixed inset-0 z-10 overflow-y-auto z-[999]">
                <div class="min-h-screen px-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay class="fixed inset-0 bg-black/50" @click="onClose" />
                    </TransitionChild>

                    <span class="inline-block h-screen align-middle" aria-hidden="true"></span>

                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <div class="inline-block w-full max-w-[56rem] h-[calc(100vh-7rem)] max-h-[56rem] my-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                            <header class="bg-primary-500 px-6 py-3">
                                <div class="flex justify-between items-center">
                                    <DialogTitle as="h3" class="h4 text-white">
                                        Post by {{ data.author.fullName }}
                                    </DialogTitle>

                                    <button type="button" class="text-white flex items-center" @click="onClose">
                                        <span class="icon w-6 h-6">
                                            <IconTimes />
                                        </span>
                                    </button>
                                </div>
                            </header>

                            <div class="h-[calc(100%-3rem)] flex">
                                <div class="w-[calc(100%-20rem)] overflow-auto">
                                    <div class="p-6">
                                        <post-header class="mb-4" :data="data" />

                                        <section class="break-words">
                                            <component :is="componentName" :data="data" full-view />
                                        </section>
                                    </div>
                                </div>

                                <aside class="w-[20rem]">
                                    <comments :element-id="data.id" :element-uid="data.uid" />
                                </aside>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { kebabCase } from 'lodash-es';

import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
} from '@headlessui/vue';

import IconTimes from '@/img/icons/times.svg';

import PostHeader from './PostHeader.vue';
import PostText from './types/PostText.vue';
import PostAudio from './types/PostAudio.vue';
import PostGallery from './types/PostGallery.vue';
import PostImage from './types/PostImage.vue';
import PostVideo from './types/PostVideo.vue';

import Comments from '@vue-components/comments/Comments.vue';

export default {
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay,
        DialogTitle,
        DialogDescription,

        IconTimes,

        PostHeader,
        PostText,
        PostAudio,
        PostGallery,
        PostImage,
        PostVideo,

        Comments,
    },

    props: {
        data: {
            type: Object,
            default: null,
        },

        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['close'],

    computed: {
        componentName() {
            if (!this.data) {
                return null;
            }

            return `post-${kebabCase(this.data.type)}`;
        },
    },

    methods: {
        onClose() {
            this.$emit('close');
        },
    },
};

</script>
