<template>
    <audio v-if="audio" class="block w-full pointer-events-none bg-gray-100 focus:outline-none" controls>
        <source :src="audio">
    </audio>
</template>

<script>
export default {
    props: {
        // eslint-disable-next-line vue/require-prop-types
        file: {
            required: true,
        },
    },

    emits: ['loaded'],

    data() {
        return {
            audio: null,
        };
    },

    mounted() {
        if (this.file.url) {
            return this.audio = this.file.url;
        }

        const reader = new FileReader();

        reader.addEventListener('load', (event) => {
            this.audio = event.target.result;
            this.$emit('loaded', this.audio);
        });

        reader.readAsDataURL(this.file);
    },
};

</script>
