<template>
    <form class="block w-full p-4" @submit.prevent="onSubmit">
        <div class="flex items-start">
            <little-pic :profile="profile" class="mr-2" />

            <div class="relative flex-1">
                <label v-if="!hasValue" :id="inputId" class="absolute px-4 pointer-events-none text-sm text-gray-400 align-y">New comment</label>

                <content-editable v-model="comment" :aria-labelledby="inputId" class="block w-full text-sm bg-gray-100 px-4 py-2 overflow-y-auto break-all min-h-[2.5rem] max-h-[10rem] rounded-[1.25rem] to-sm:text-[16px]" />
            </div>
        </div>

        <div class="flex items-center mt-4">
            <slot></slot>

            <button type="submit" class="btn btn-primary btn-medium block flex-1" :class="{ loading }" :disabled="!hasValue || loading">Post</button>
        </div>
    </form>
</template>

<script>
import { gql } from 'graphql-tag';

import LittlePic from './LittlePic.vue';
import ContentEditable from '@vue-components/ContentEditable.vue';

import { GET_COMMENTS_QUERY, GET_COMMENT_PIC_QUERY, CREATE_COMMENT } from '@graphql/Comments';

export default {
    components: {
        LittlePic,
        ContentEditable,
    },

    inject: [
        'elementId',
    ],

    emits: ['save'],

    data() {
        return {
            profile: null,
            comment: '',
            loading: false,
        };
    },

    computed: {
        inputId() {
            return `comment-${this.elementId}`;
        },

        hasValue() {
            return this.comment.trim() !== '';
        },

        userId() {
            return this.$store.state.userId;
        },
    },

    apollo: {
        profile: {
            query: GET_COMMENT_PIC_QUERY,

            variables() {
                return {
                    id: [this.userId],
                };
            },

            skip() {
                return !this.userId;
            },
        },
    },

    methods: {
        onSubmit() {
            this.loading = true;

            this.$apollo.mutate({
                mutation: CREATE_COMMENT,

                variables: {
                    ownerId: this.elementId,
                    comment: this.comment,
                },

                update: (store, { data: { saveComment } }) => {
                    // We must supply the exact query and variables to match the current cached query
                    const commentQuery = {
                        query: GET_COMMENTS_QUERY,
                        variables: {
                            ownerId: this.elementId,
                            limit: 20,
                            offset: 0,
                        },
                    };

                    // Fetch the existing cached data
                    const dataInStore = store.readQuery(commentQuery);

                    // Update it (in a reactive way)
                    store.writeQuery({
                        ...commentQuery,
                        data: {
                            ...dataInStore,
                            comments: [...dataInStore.comments, saveComment],
                        },
                    });
                },
            }).then(({ data }) => {
                this.loading = false;
                this.comment = '';

                this.$emit('save');
            }).catch((error) => {
                this.loading = false;

                console.error(error);
            });
        },
    },
};

</script>
