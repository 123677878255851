<template>
    <div
        class="relative block w-full bg-gray-100"
        :style="{ 'padding-top': `${aspectRatio * 100}%` }"
    >
        <div class="flex items-center justify-center absolute inset-0">
            <img
                ref="container"
                class="w-full h-full"
                :src="dataUri"
                alt="Crop Image"
            >
        </div>
    </div>
</template>

<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    props: {
        file: {
            type: File,
            required: true,
        },

        aspectRatio: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            // 1px transparent pixel.
            dataUri: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
            cropper: null,
        };
    },

    mounted() {
        const { container } = this.$refs;

        if (this.cropper) {
            this.cropper.destroy();
        }

        const reader = new FileReader();

        reader.addEventListener('load', async(event) => {
            this.dataUri = event.target.result;

            await this.$nextTick();

            const aspectRatio = 1 / this.aspectRatio;

            this.cropper = new Cropper(container, {
                aspectRatio,
                viewMode: 1,
                autoCropArea: 1,
                zoomable: false,
            });
        });

        reader.readAsDataURL(this.file);
    },

    beforeUnmount() {
        this.cropper.destroy();
    },
};

</script>
