<template>
    <div>
        <slot :topics="topics" :update-topics="updateTopics"></slot>
    </div>
</template>

<script>

export default {
    data() {
        return {
            topics: [],
        };
    },

    created() {
        try {
            this.topics = JSON.parse(localStorage.feedTopics);
        } catch (e) {
            // Do nothing
        }
    },

    methods: {
        updateTopics(newValue) {
            this.topics = newValue.concat();

            // Save picked values to localStorage
            localStorage.feedTopics = JSON.stringify(newValue);
        },
    },
};

</script>
