<template>
    <component
        :is="tag"
        ref="element"
        :contenteditable="contenteditable"
        @input="update"
        @blur="update"
        @paste="onPaste"
        @keypress="onKeypress"
    />
</template>

<script>
function replaceAll(str, search, replacement) {
    return str.split(search).join(replacement);
}

export default {
    props: {
        tag: {
            type: String,
            default: 'div',
        },

        contenteditable: {
            type: Boolean,
            default: true,
        },

        modelValue: {
            type: String,
            default: '',
        },

        noHtml: {
            type: Boolean,
            default: true,
        },

        noNl: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:modelValue', 'returned', 'keypress', 'paste'],

    watch: {
        modelValue(newVal) {
            if (newVal !== this.currentContent()) {
                this.updateContent(newVal);
            }
        },
    },

    mounted() {
        this.updateContent(this.modelValue);
    },

    methods: {
        currentContent() {
            if (this.noHtml && this.$refs.element) {
                return this.$refs.element.textContent;
            }

            return this.$refs.element.innerHTML;
        },

        updateContent(newContent) {
            if (newContent === null || newContent === undefined || newContent.trim() === '') {
                this.$refs.element.innerHTML = '<br />';
                return;
            }

            if (this.noHtml) {
                this.$refs.element.textContent = newContent;
            } else {
                this.$refs.element.innerHTML = newContent;
            }
        },

        update() {
            this.$emit('update:modelValue', this.currentContent());
        },

        onPaste(event) {
            event.preventDefault();
            let text = (event.originalEvent || event).clipboardData.getData('text/plain');

            if (this.noNl) {
                text = replaceAll(text, '\r\n', ' ');
                text = replaceAll(text, '\n', ' ');
                text = replaceAll(text, '\r', ' ');
            }

            window.document.execCommand('insertText', false, text);

            this.fwdEv(event);
        },

        onKeypress(event) {
            if (event.key === 'Enter' && this.noNl) {
                event.preventDefault();
                this.$emit('returned', this.currentContent);
            }

            this.fwdEv(event);
        },

        fwdEv(event) {
            this.$emit(event.type, event);
        },
    },
};

</script>
