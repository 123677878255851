import { gql } from 'graphql-tag';

export const GET_MAP_QUERY = gql`
    query($sections: [String]!) {
        entries(section: $sections) {
            id
            url
            title
            secton: sectionHandle

            ... on campaigns_campaigns_Entry {
                location {
                    lat
                    lng
                }
            }
            ... on organisations_organisations_Entry {
                location {
                    lat
                    lng
                }
            }
        }
    }
`;

export const GET_MAP_ENTRY_QUERY = gql`
    query ($id: [QueryArgument]!) {
        selectedEntry: entry(id: $id) {
            id
            title
            url

            ... on campaigns_campaigns_Entry {
                shortDescription: aboutYourCampaign

                featuredImage: campaignFeaturedImage {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transform(width: 300, height: 300, immediately: false)
                }
            }

            ... on organisations_organisations_Entry {
                shortDescription: introduction

                featuredImage: organisationFeaturedImage {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transform(width: 300, height: 300, immediately: false)
                }
            }
        }
    }
`;
