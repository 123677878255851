<template>
    <Float
        placement="bottom-end"
        :offset="4"
        :z-index="100"
        flip
        enter="transition duration-100 ease-out"
        enter-from="transform scale-95 opacity-0"
        enter-to="transform scale-100 opacity-100"
        leave="transition duration-75 ease-in"
        leave-from="transform scale-100 opacity-100"
        leave-to="transform scale-95 opacity-0"
        tailwindcss-origin-class
    >
        <slot></slot>
    </Float>
</template>

<script>
import { Float } from 'headlessui-float-vue';

export default {
    components: {
        Float,
    },
};

</script>
