<template>
    <div class="relative h-screen">
        <location-map :filter="filter" @selected="onSelectItem" />

        <button class="main-menu-button md:hidden absolute top-0 left-0 ml-8 mt-8" type="button" @click.prevent="onToggleVisibility">
            <span class="icon w-4 h-5 block mx-auto">
                <IconEllipsisV />
            </span>
        </button>

        <aside class="absolute left-0 right-0 inset-y-0 p-8 overflow-auto z-[999] md:right-auto md:w-[400px]" :class="{ 'hidden md:block': !isMenuOpen }">
            <div class="bg-white rounded-lg p-8">
                <div class="flex items-center mb-4">
                    <button class="main-menu-button md:hidden" type="button" @click.prevent="onToggleVisibility">
                        <span class="icon w-4 h-4 block mx-auto">
                            <IconTimes />
                        </span>
                    </button>

                    <div class="flex-1 ml-4 md:ml-0">
                        <h1 class="h3">{{ entry.title }}</h1>
                    </div>
                </div>

                <div class="form-check flex items-center mr-2 mb-0 py-2">
                    <input id="field-campaigns" v-model="filter.campaigns" class="form-check-input mt-0" type="checkbox" name="map[campaigns]" value="1">
                    <label class="form-check-label" for="field-campaigns">Campaigns</label>
                </div>

                <div class="form-check flex items-center mr-2 mb-0 py-2">
                    <input id="field-organisations" v-model="filter.organisations" class="form-check-input mt-0" type="checkbox" name="map[organisations]" value="1">
                    <label class="form-check-label" for="field-organisations">Organisations</label>
                </div>

                <div v-if="selectedEntry || $apollo.queries.selectedEntry.loading" class="mt-8 pt-8 border-t border-gray-100">
                    <div v-if="$apollo.queries.selectedEntry.loading" class="text-center">
                        <loading />
                    </div>

                    <div v-else>
                        <a :href="selectedEntry.url">
                            <div class="aspect aspect-16x9">
                                <img :src="selectedEntry.featuredImage[0]?.url" :alt="selectedEntry.title">
                            </div>

                            <div class="w-full flex-1 bg-white mt-4">
                                <h2 class="h3">{{ selectedEntry.title }}</h2>

                                <div class="richtext mt-2 text-sm">{{ selectedEntry.shortDescription }}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </aside>
    </div>
</template>

<script>
import Loading from '@vue-components/Loading.vue';
import LocationMap from '@vue-components/LocationMap.vue';
import { GET_MAP_ENTRY_QUERY } from '@graphql/Explore';
import IconEllipsisV from '@/img/icons/ellipsis-v.svg';
import IconTimes from '@/img/icons/times.svg';

export default {
    components: {
        IconEllipsisV,
        IconTimes,

        Loading,
        LocationMap,
    },

    props: {
        entry: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            filter: {
                campaigns: true,
                organisations: true,
            },
            isMenuOpen: false,
            selectedEntry: null,
            selectedEntryId: null,
        };
    },

    apollo: {
        selectedEntry: {
            query: GET_MAP_ENTRY_QUERY,

            variables() {
                return {
                    id: this.selectedEntryId,
                };
            },

            skip() {
                return !this.selectedEntryId;
            },
        },
    },

    methods: {
        onSelectItem(id) {
            this.selectedEntryId = id;
            this.isMenuOpen = true;
        },

        onToggleVisibility() {
            this.isMenuOpen = !this.isMenuOpen;
        },
    },
};

</script>
