<template>
    <tags-input
        v-model="proxyValue"
        :existing-tags="formattedTags"
        :limit="5"
        only-existing-tags
        :typeahead-activation-threshold="0"
        typeahead-style="dropdown"
        typeahead-hide-discard
        typeahead
    >
        <template #before-tags>
            <slot></slot>
        </template>

        <template #selected-tag="{ tag, index, removeTag }">
            <span class="icon w-4 h-4 mr-2">
                <IconTags />
            </span>

            <span>{{ tag.value }}</span>

            <button class="ml-2 text-gray-400 focus:outline-none" type="button" @click.prevent="removeTag(index)">
                <span class="icon w-3 h-3 block">
                    <IconTimes />
                </span>
            </button>
        </template>

        <template #before-search-result>
            <span class="icon w-5 h-5 m-2">
                <IconTags />
            </span>
        </template>
    </tags-input>
</template>

<script>
import { gql } from 'graphql-tag';

import TagsInput from '@vue-components/vendor/TagsInput.vue';

import IconTags from '@/img/icons/tags.svg';
import IconTimes from '@/img/icons/times.svg';

export const GET_TAGS_QUERY = gql`
    query ($search: String) {
        tags: categories(search: $search, group: "tags", orderBy: "level DESC, title") {
            id
            title
            level
            parent {
                id
            }
        }
    }
`;

export default {
    components: {
        TagsInput,
        IconTags,
        IconTimes,
    },

    props: {
        modelValue: {
            type: Array,
            required: true,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            query: '',
            tags: [],
        };
    },

    apollo: {
        tags: {
            query: GET_TAGS_QUERY,
        },
    },

    computed: {
        formattedTags() {
            return this.tags.map((tag) => {
                return {
                    key: tag.id,
                    value: tag.title,
                };
            });
        },

        proxyValue: {
            get() {
                return this.modelValue;
            },

            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },
};

</script>
