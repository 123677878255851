import { gql } from 'graphql-tag';

export const GET_POST_QUERY = gql`
    query ($id: [QueryArgument]!) {
        data: entry(section: "posts", id: $id) {
            id
            uid
            postDate
            type: typeHandle

            author {
                id
                uid
                fullName

                photo {
                    id
                    url @transform(width: 64, height: 64, immediately: false)
                }

                ... on User {
                    userSlug
                }
            }

            wishlistItem {
                id
                elementId
            }

            ... on posts_text_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                link: postLink(limit: 1) {
                    id

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }

            ... on posts_image_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                image: postImage(limit: 1) {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transform(handle: "large", immediately: false)
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }

            ... on posts_video_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                video: postVideo(limit: 1) {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transcode
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }

            ... on posts_audio_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                audio: postAudio(limit: 1) {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url
                    mimeType
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }

            ... on posts_gallery_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                images: postGallery(limit: 8) {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    size
                    url @transform(handle: "large", immediately: false)
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }
        }
    }
`;


// Note that apollo-client has an issue with fragments - bah
// https://github.com/apollographql/apollo-client/issues/3402
export const GET_POSTS_QUERY = gql`
    query ($topics: [QueryArgument], $author: [QueryArgument], $entry: [QueryArgument], $tags: [QueryArgument], $limit: Int!, $offset: Int!) {
        posts: entries(
            section: "posts",
            authorId: $author,
            linkedEntry: $entry,
            orderBy: "postDate DESC",
            topic: $topics,
            tags: $tags,
            limit: $limit,
            offset: $offset
        ) {
            id
            uid
            postDate
            type: typeHandle

            author {
                id
                uid
                fullName

                photo {
                    id
                    url @transform(width: 64, height: 64, immediately: false)
                }

                ... on User {
                    userSlug
                }
            }

            wishlistItem {
                id
                elementId
            }

            ... on posts_text_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                link: postLink(limit: 1) {
                    id

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }

            ... on posts_image_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                image: postImage(limit: 1) {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transform(handle: "large", immediately: false)
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }

            ... on posts_video_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                video: postVideo(limit: 1) {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transcode
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }

            ... on posts_audio_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                audio: postAudio(limit: 1) {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url
                    mimeType
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }

            ... on posts_gallery_Entry {
                linkedEntry {
                    id
                    uid
                    uri
                    title

                    ... on organisations_organisations_Entry {
                        photo: organisationFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                    
                    ... on campaigns_campaigns_Entry {
                        photo: campaignFeaturedImage {
                            url @transform(width: 96, height: 96, immediately: false)
                        }
                    }
                }

                topic {
                    id
                }

                postText
                text: postText @chop(limit: 100, append: "[more]")

                images: postGallery(limit: 8) {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    size
                    url @transform(handle: "large", immediately: false)
                }

                tags(limit: 6) {
                    id
                    slug
                    title
                }
            }
        }
    }
`;


export const GET_POST_ENTRIES_QUERY = gql`
    query($userId: [QueryArgument]!) {
        entries(section: ["campaigns", "organisations"], authorisedUsers: $userId, orderBy: "sectionId, title") {
            id
            uid
            title
            sectionHandle

            ... on campaigns_campaigns_Entry {
                image: campaignFeaturedImage {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transform(width: 80, height: 80, immediately: false)
                }
            }

            ... on organisations_organisations_Entry {
                image: organisationFeaturedImage {
                    id
                    title
                    kind
                    mimeType
                    width
                    height
                    focalPoint
                    hasFocalPoint
                    originalUrl: url

                    embeddedAsset {
                        title
                        url
                        type
                        description

                        image
                        imageWidth
                        imageHeight

                        aspectRatio
                        html
                    }

                    url @transform(width: 80, height: 80, immediately: false)
                }
            }
        }
    }
`;
