<template>
    <div class="h-full">
        <div class="absolute inset-0">
            <div ref="map" class="block w-full h-full"></div>
        </div>
    </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-active-area';
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import { GET_MAP_QUERY } from '@graphql/Explore';

const markerPrimary = `<div class="block w-6 h-auto text-center">
    <svg class="block w-6 h-auto text-primary-500" aria-hidden="true" focusable="false" data-prefix="fas" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 524">
        <path fill="currentColor" d="M178.27,507.67C33,297,6,275.41,6,198,6,92,92,6,198,6S390,92,390,198c0,77.41-27,99-172.27,309.67a24,24,0,0,1-39.46,0ZM198,278a80,80,0,1,0-80-80A80,80,0,0,0,198,278Z" stroke="#fff" stroke-miterlimit="10" stroke-width="20"/>
        <circle cx="198" cy="198" r="80" fill="#fff"/>
    </svg>
</div>`;

const markerSecondary = `<div class="block relative w-6 h-auto text-center">
    <svg class="block w-6 h-auto text-secondary-500" aria-hidden="true" focusable="false" data-prefix="fas" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396 524">
        <path fill="currentColor" d="M178.3,507.7C33,297,6,275.4,6,198,6,92,92,6,198,6S390,92,390,198c0,77.4-27,99-172.3,309.7a23.9,23.9,0,0,1-33.4,6,24.4,24.4,0,0,1-6-6Z" stroke="#fff" stroke-miterlimit="10" stroke-width="20"/>
    </svg>

    <span class="block absolute inset-x-0 top-0 pt-1 -mt-px text-xs font-bold text-center text-white">{{ number }}</span>
</div>`;


export default {
    props: {
        filter: {
            type: Object,
            required: true,
        },
    },

    emits: ['selected'],

    data() {
        return {
            map: null,
            markers: null,
        };
    },

    watch: {
        entries() {
            // this.setMapBounds();
            this.setMapPins();
        },
    },

    mounted() {
        const { map } = this.$refs;

        this.map = L.map(map, {
            attributionControl: false,
            zoomControl: false,
            layers: [
                L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                    attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
                    tileSize: 512,
                    maxZoom: 18,
                    zoomOffset: -1,
                    id: 'mapbox/satellite-v9',
                    accessToken: 'pk.eyJ1IjoidGFzZGV2IiwiYSI6ImNrMmo0ZTRzMDFucGYzbnQ4cDZkYnF4YmMifQ.Sy4EyNKz7I6ZJaxxalbi1w',
                }),
            ],
            center: [-37.840935, 144.946457],
            zoom: 6,
        });

        this.map.setActiveArea({
            position: 'absolute',
            top: '0px',
            bottom: '0px',
            left: '400px',
            right: '0px',
        }, true);

        L.control.attribution({
            position: 'bottomright',
        }).addTo(this.map);
    },

    apollo: {
        entries: {
            query: GET_MAP_QUERY,
            variables() {
                const sections = [];

                if (this.filter.campaigns) {
                    sections.push('campaigns');
                }

                if (this.filter.organisations) {
                    sections.push('organisations');
                }

                return {
                    sections,
                };
            },
        },
    },

    methods: {
        setMapBounds() {
            const latLngBounds = new L.LatLngBounds();

            if (this.entries.length > 0) {
                for (const entry of this.entries) {
                    const isValidLat = entry.location.lat && entry.location.lat >= -90 && entry.location.lat <= 90;
                    const isValidLng = entry.location.lng && entry.location.lng >= -180 && entry.location.lng <= 180;

                    if (isValidLat && isValidLng) {
                        const latLng = new L.LatLng(entry.location.lat, entry.location.lng);
                        latLngBounds.extend(latLng);
                    }
                }
            } else {
                latLngBounds.extend(new L.LatLng(-37.840935, 144.946457));
            }

            if (latLngBounds.isValid()) {
                this.map.fitBounds(latLngBounds, {
                    maxZoom: 6,
                    padding: [50, 50],
                });
            }
        },

        setMapPins() {
            if (this.markers) {
                this.map.removeLayer(this.markers);
            }

            this.markers = L.markerClusterGroup({
                maxClusterRadius: 20,
                iconCreateFunction: (cluster) => {
                    return L.divIcon({
                        html: markerSecondary.replace('{{ number }}', cluster.getChildCount()),
                        iconSize: [24, 31.75],
                    });
                },
            });

            for (const entry of this.entries) {
                const icon = L.divIcon({
                    id: entry.id,
                    uri: entry.uri,
                    html: markerPrimary,
                    iconSize: [24, 31.75],
                });

                if (entry.location.lat && entry.location.lng) {
                    const pin = L.marker(new L.latLng(entry.location.lat, entry.location.lng), { icon });
                    pin.on('click', this.onPinClick);
                    this.markers.addLayer(pin);
                }
            }

            this.map.addLayer(this.markers);
        },

        onPinClick(event) {
            const { id, uri } = event.target.options.icon.options;
            console.log(id);

            // this.$router.push(`/${uri}`);
            this.$emit('selected', id);
        },
    },
};

</script>

<style lang="scss">
    .leaflet-div-icon {
        outline: none !important;
        background: none !important;
        border: 0 !important;
    }
</style>
