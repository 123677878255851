<template>
    <video v-if="video" class="block w-full rounded overflow-hidden pointer-events-none bg-gray-100 focus:outline-none">
        <source :src="video">
    </video>
</template>

<script>
export default {
    props: {
        // eslint-disable-next-line vue/require-prop-types
        file: {
            required: true,
        },
    },

    emits: ['loaded'],

    data() {
        return {
            video: null,
        };
    },

    mounted() {
        if (this.file.url) {
            return this.video = this.file.url;
        }

        const reader = new FileReader();

        reader.addEventListener('load', (event) => {
            this.video = event.target.result;
            this.$emit('loaded', this.video);
        });

        reader.readAsDataURL(this.file);
    },
};

</script>
