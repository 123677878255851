import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { $$ } from '@utils/dom';

class MiniMap {
    constructor($miniMap) {
        this.$map = $miniMap.querySelector('[data-map]');
        this.$select = $miniMap.querySelector('[data-select]');
        this.locations = JSON.parse($miniMap.getAttribute('data-locations'));

        this.map = L.map(this.$map, {
            attributionControl: false,
            zoomControl: false,
            layers: [
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                }),
            ],
        });

        L.control.attribution({
            position: 'bottomright',
        }).addTo(this.map);

        this.setMapPin(this.locations[0]);

        this.$select.addEventListener('change', (e) => {
            const locationId = e.target.value;

            const location = this.locations.find((location) => {
                return location.ownerId == locationId;
            });

            this.setMapPin(location);
        });
    }

    setMapPin(location) {
        if (this.marker) {
            this.map.removeLayer(this.marker);
        }

        const latLng = new L.latLng([location.lat, location.lng]);
        const latLngBounds = new L.latLngBounds();

        latLngBounds.extend(latLng);

        const icon = L.divIcon({
            className: 'text-red-600',
            iconSize: [14, 25],
            iconAnchor: [7, 25],
            html: `
                <svg
                aria-hidden="true"
                focusable="false"
                class="block w-full"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 288 512"
                >
                    <path fill="#000" d="M144 320a175.77 175.77 0 0 0 32-3.06v156.69l-22 33a12 12 0 0 1-20 0l-22-33V316.94a175.77 175.77 0 0 0 32 3.06z"></path>
                    <path fill="currentColor" d="M144 0a144 144 0 1 0 144 144A144 144 0 0 0 144 0zm0 76a68.07 68.07 0 0 0-68 68 12 12 0 0 1-24 0 92.11 92.11 0 0 1 92-92 12 12 0 0 1 0 24z"></path>
                </svg>
            `,
        });

        this.marker = L.marker(latLng, { icon });
        this.map.addLayer(this.marker);

        this.map.fitBounds(latLngBounds, {
            maxZoom: 14,
            padding: [10, 10],
        });
    }
}

export default class {
    constructor(config) {
        // Wait for the DOM to be ready before proceeding
        if (typeof document !== 'undefined') {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', this.instantiateTabs.bind(this));
            } else if (window.requestAnimationFrame) {
                window.requestAnimationFrame(this.instantiateTabs.bind(this));
            } else {
                window.setTimeout(this.instantiateTabs.bind(this), 16);
            }
        }
    }

    instantiateTabs() {
        $$('[data-mini-map]').forEach(($miniMap) => {
            new MiniMap($miniMap);
        });
    }
}
