<template>
    <Popover v-slot="{ open }">
        <MenuFloat placement="bottom-end" :offset="7.5">
            <PopoverButton
                class="toolbar__item"
                :class="{
                    'toolbar__item--active': open,
                    'toolbar__item--disclosure': notificationsCount > 0,
                }"
                @click="isShown = true"
            >
                <slot name="button"></slot>
            </PopoverButton>

            <PopoverPanel class="w-[16rem] origin-top-right bg-white divide-y divide-gray-100 rounded shadow ring-1 ring-black ring-opacity-5 focus:outline-none text-xs text-gray-900">
                <div class="overflow-auto max-h-[20rem]">
                    <div v-infinite-scroll="onLoadMore" infinite-scroll-disabled="disableLazyLoad" infinite-scroll-distance="10">
                        <div v-if="$apollo.queries.notifications.loading && notifications.length === 0" class="text-center p-4">
                            <loading />
                        </div>

                        <slot v-else-if="notifications.length === 0" name="no-results"></slot>

                        <div v-else>
                            <div v-for="notification in notifications" :key="notification.id" :notification="notification">
                                <component :is="link(notification.element) ? 'a' : 'div'" :href="link(notification.element)" class="notification flex items-center px-4 py-3 border-t border-gray-100 last:border-none" :class="notification.read ? 'notification--read' : 'notification--unread relative pr-6'">
                                    <div class="flex-shrink-0 mr-4">
                                        <little-pic :profile="notification.from">
                                            <template #empty>
                                                <!-- eslint-disable-next-line -->
                                            <svg id="f0d7ee36-c33d-427e-8343-ee45c458c692" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.4 77.4" class="w-10 h-10"><circle cx="38.7" cy="38.7" r="38.7" fill="#0054a5"></circle><path d="M16.3,7.2a4.1,4.1,0,0,1,2.4.8c1.5,1.9-1.5,4.6-3.8,7.2s-3.9,6-1,7.2S15.5,26,12.3,28s-6.9,6.2-3.4,8.2,7,0,11.3,1.5,8.2,5.4,7.2,10.5-.8,8.7-3.5,9.8-3.4,1.5-3.4,4.5.8,6.4-.8,6.4-3.3-4.6-3.2-6.9,1.7-3.3,0-5.9-7.3-6.4-7-9.5,3.5-4.4,2-5.6S4.6,37.7.3,34.1A38.6,38.6,0,0,1,16.3,7.2ZM64,15.4c-3-.8-7.8-2.9-9.9-2.7s-4,.7-4,2.1c0,3.5,3.6,2.6,5.3,4.3s-1.9,1.1-5,1.8S46,23,46.2,24.7s.2,2.9,3.9,2.9,7,1,7.2,2.1-4.4.6-7.8.7-11,1.6-12.2,5.9a8.1,8.1,0,0,0,5,9.9c4,1.3,6.9,2.3,7.2,8.5s.2,10.7,3.3,11.3,6.8-4.8,8.5-9.4,4-11.3,2-13.2-7.6-3.1-7.3-5.3,1-1.7,3.4-1.5,7.8,4.5,9.7,4,2.2-3.8,1-3.8-3.9-.2-3.8-1.4.7-2.7,2.5-2.3,5.9,2.4,6.5,5.1,1,4.1,1.6,6.1a36,36,0,0,0,.4-5.6,39.3,39.3,0,0,0-8.4-24.2A7,7,0,0,1,64,15.4ZM28.1,9.5c-3-.3-3.5,10.8-.5,16.2,1.4,2.6,3.4,3.9,5.3,2.2s4.1-3.6,6.7-6.4S43,10.8,40.9,9.8s-5.8,6.9-8.1,7.1C32.8,16.9,31.1,10,28.1,9.5Zm36.4,48c-.5-.1-.9.5-2.2,2.3a5.7,5.7,0,0,0-1.5,4.3A1.7,1.7,0,0,0,62.4,66h.2c.9.1,1.5-2.3,2.4-4s.2-2.2,0-3.6S65,57.6,64.5,57.5Z" fill="#50b748"></path><path d="M58,5.1A38.7,38.7,0,0,1,5.1,58,38.7,38.7,0,0,0,72.2,19.4,38.2,38.2,0,0,0,58,5.1Z" fill="#00000a" opacity="0.2" style="isolation: isolate;"></path></svg>
                                            </template>
                                        </little-pic>
                                    </div>

                                    <div class="flex-1">
                                        <p class="mb-0 text-xs font-medium leading-tight">{{ widont(notification.message) }}</p>
                                        <time class="text-primary-400 text-xs">{{ timeAgo(notification.dateCreated) }}</time>
                                    </div>
                                </component>
                            </div>

                            <div v-if="$apollo.queries.notifications.loading" class="text-center">
                                <loading />
                            </div>
                        </div>
                    </div>
                </div>
            </PopoverPanel>
        </MenuFloat>
    </Popover>
</template>

<script>
import { gql } from 'graphql-tag';
import { format } from 'timeago.js';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

import Loading from '@vue-components/Loading.vue';
import LittlePic from '@vue-components/comments/LittlePic.vue';
import InfiniteScroll from '@vue-directives/InfiniteScroll';
import MenuFloat from '@vue-components/MenuFloat.vue';

export const widont = (string) => { return string.replace(/ ([^ ]+)$/, '\u00A0$1'); };

export const GET_UNERAD_NOTIFICATIONS_QUERY = gql`{
    notificationsCount(read: false)
}`;

export const GET_NOTIFICATIONS_QUERY = gql`
    query ($limit: Int!, $offset: Int!) {
        notifications(limit: $limit, offset: $offset) {
            id
            message
            read
            dateCreated

            from {
                ... on User {
                    id
                    uid
                    fullName
                    firstName
                    lastName

                    photo {
                        id
                        url @transform(width: 80, height: 80, immediately: false)
                    }
                }

                ... on organisations_organisations_Entry {
                    id
                    uid
                    fullName: title
                    alt: title

                    image: organisationFeaturedImage {
                        id
                        url @transform(width: 80, height: 80, immediately: false)
                    }
                }

                ... on campaigns_campaigns_Entry {
                    id
                    uid
                    fullName: title
                    alt: title

                    image: campaignFeaturedImage {
                        id
                        url @transform(width: 80, height: 80, immediately: false)
                    }
                }
            }

            element {
                id
                uid
            }
        }
    }
`;

export default {
    components: {
        Loading,
        LittlePic,

        MenuFloat,
        Popover,
        PopoverButton,
        PopoverPanel,
    },

    directives: {
        InfiniteScroll,
    },

    data() {
        return {
            notifications: [],
            notificationsCount: 0,
            limit: 10,
            page: 0,
            noMore: false,
            isShown: false,
        };
    },

    apollo: {
        notificationsCount: {
            query: GET_UNERAD_NOTIFICATIONS_QUERY,
            pollInterval: 60 * 1000,
        },

        notifications: {
            query: GET_NOTIFICATIONS_QUERY,

            variables() {
                return {
                    limit: this.limit,
                    offset: 0,
                };
            },

            skip() {
                return !this.isShown;
            },
        },
    },

    computed: {
        disableLazyLoad() {
            return this.noMore;
        },
    },

    watch: {
        notifications() {
            this.markAsRead();
        },
    },

    methods: {
        widont(string) {
            return widont(string);
        },

        timeAgo(string) {
            return format(string);
        },

        link(element) {
            if (element && element.__typename.substr(0, 6) === 'posts_') {
                return `/feed/${element.uid}`;
            }

            return null;
        },

        onLoadMore() {
            this.page++;

            const offset = this.page * this.limit;

            this.$apollo.queries.notifications.fetchMore({
                variables: {
                    limit: this.limit,
                    offset,
                },

                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const notifications = [...previousResult.notifications, ...fetchMoreResult.notifications];

                    this.$nextTick(() => {
                        this.noMore = fetchMoreResult.notifications.length < this.limit;
                    });

                    return {
                        notifications,
                    };
                },
            });
        },

        async markAsRead() {
            if (Array.isArray(this.notifications)) {
                const ids = this.notifications
                    .filter((notification) => { return !notification.read; })
                    .map((notification) => { return notification.id; });

                if (ids.length > 0) {
                    await this.$axios.post('/actions/feed/notifications/mark-as-read', {
                        ids,
                    });

                    this.refreshNotifications();
                }
            }
        },

        async refreshNotifications() {
            // Just update the badge
            await this.$apollo.queries.notificationsCount.refetch();
        },
    },
};

</script>

<style lang="scss">
    .notification--unread::after {
        @apply absolute right-0 align-y block w-2 h-2 mr-4 bg-primary-500 rounded-full;

        content: '';
    }
</style>
