<template>
    <div>
        <truncated v-if="fullView" disabled class="mb-4">
            {{ data.postText }}
        </truncated>

        <truncated v-else class="mb-4">
            {{ data.text }}

            <template #moreButton>
                <a href="#" class="link text-sm" @click.prevent="openModal">Read more</a>
            </template>
        </truncated>

        <img :src="image.url" :alt="image.title" class="block w-full">
    </div>
</template>

<script>
import Truncated from '@vue-components/Truncated.vue';

export default {
    components: {
        Truncated,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },

        fullView: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        image() {
            return this.data.image[0] || null;
        },
    },

    methods: {
        openModal() {
            this.$events.emit(`post.detail.${this.data.id}`);
        },
    },
};

</script>
