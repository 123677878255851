<template>
    <transition-group name="topics" tag="ul">
        <li v-for="topic in sortedTopics" :key="topic.id" class="topic border-t border-transparent text-sm transition mb-2 last:mb-0" :class="isActive(topic) ? 'topic--active' : 'topic--inactive'">
            <div class="relative">
                <input :id="inputId(topic)" v-model="proxyTopics" :value="topic.id" type="checkbox" class="topic__input sr-only" name="topics[]">

                <label :for="inputId(topic)" class="topic__label flex items-center w-full p-1 bg-gray-100 rounded-full cursor-pointer">
                    <span aria-hidden="true" class="topic__icon icon block relative w-10 h-10 mr-4 bg-gray-300 rounded-full transition-colors">
                        <span class="block absolute w-5 h-5 align-x-y text-white" v-html="topic.icon"></span>
                    </span>

                    <span>{{ topic.title }}</span>
                </label>

                <span class="topic__switch absolute block w-6 h-4 right-0 align-y mr-4 bg-gray-300 rounded-full pointer-events-none transition-colors">
                    <span class="block absolute w-3 h-3 bg-gray-100 rounded-full top-[0.125rem] left-[0.125rem] transition-property-[left] transition-property-right"></span>
                </span>
            </div>
        </li>
    </transition-group>
</template>

<script>

export default {
    props: {
        allTopics: {
            type: Array,
            required: true,
        },

        topics: {
            type: Array,
            default: () => { return []; },
        },
    },

    emits: ['update:topics'],

    data() {
        return {
            proxyTopics: this.topics,
        };
    },

    computed: {
        sortedTopics() {
            const sorted = [];

            for (const topic of this.allTopics) {
                for (const id of this.proxyTopics) {
                    if (topic.id === id) {
                        sorted.push(topic);
                        break;
                    }
                }
            }

            for (const topic of this.allTopics) {
                if (!this.proxyTopics.includes(topic.id)) {
                    sorted.push(topic);
                }
            }

            return sorted;
        },
    },

    watch: {
        proxyTopics(newValue) {
            this.$emit('update:topics', newValue);
        },
    },

    methods: {
        inputId(topic) {
            return `topic-${topic.id}`;
        },

        isActive(topic) {
            return this.proxyTopics.includes(topic.id);
        },
    },
};

</script>

<style lang="scss">
    .topics-move {
        transition: transform 0.5s;
    }

    .topic {
        &__input:checked ~ &__switch {
            @apply bg-primary-500;

            > span {
                left: 0.625rem;
            }
        }

        &__input:checked + &__label > &__icon {
            @apply bg-secondary-500;
        }

        &--active + &--inactive {
            @apply mt-3 pt-3 border-gray-100;
        }
    }
</style>
