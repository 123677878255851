<template>
    <div v-if="!$apollo.queries.data.loading && data">
        <post-modal :is-open="modalOpen" :data="data" @close="closeModal" />
    </div>
</template>

<script>
import PostModal from '@vue-components/posts/PostModal.vue';

import { GET_POST_QUERY } from '@graphql/Posts';

export default {
    components: {
        PostModal,
    },

    props: {
        postId: {
            type: [String, Number],
            default: null,
        },
    },

    data() {
        return {
            modalOpen: true,
        };
    },

    apollo: {
        data: {
            query: GET_POST_QUERY,

            variables() {
                return {
                    id: [this.postId],
                };
            },
        },
    },

    methods: {
        closeModal() {
            // Prevent routing from triggering too much
            if (!this.modalOpen) {
                return;
            }

            window.history.pushState(null, null, '/feed');

            this.modalOpen = false;
        },
    },
};

</script>
