<template>
    <div class="h-full flex flex-col justify-end">
        <div ref="comments" class="overflow min-h-[0px] overflow-auto">
            <loading v-if="$apollo.queries.comments.loading" class="!block mx-auto" />

            <div v-if="comments.length > 0" class="p-3">
                <div v-infinite-scroll="onLoadMore" infinite-scroll-disabled="disableLazyLoad" infinite-scroll-distance="10" infinite-scroll-reverse>
                    <comment v-for="comment in orderedComments" :key="comment.id" :data="comment" />
                </div>
            </div>
        </div>

        <new-comment v-if="userId" @save="onNewComment" />
    </div>
</template>

<script>
import { gql } from 'graphql-tag';
import { GET_COMMENTS_QUERY } from '@graphql/Comments';

import Comment from './Comment.vue';
import NewComment from './NewComment.vue';
import Loading from '@vue-components/Loading.vue';
import InfiniteScroll from '@vue-directives/InfiniteScroll';

export default {
    components: {
        NewComment,
        Comment,
        Loading,
    },

    directives: {
        InfiniteScroll,
    },

    provide() {
        return {
            elementId: this.elementId,
        };
    },

    props: {
        expanded: {
            type: Boolean,
            default: false,
        },

        elementId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            noMore: false,
            page: 0,
            comments: [],
        };
    },

    computed: {
        limit() {
            return 20;
        },

        disableLazyLoad() {
            return this.$apollo.queries.comments.loading || this.noMore;
        },

        userId() {
            return this.$store.state.userId;
        },

        orderedComments() {
            return this.comments.slice().reverse();
        },
    },

    apollo: {
        comments: {
            query: GET_COMMENTS_QUERY,

            variables() {
                return {
                    ownerId: this.elementId,
                    limit: this.limit,
                    offset: 0,
                };
            },
        },
    },

    methods: {
        onLoadMore() {
            this.page++;

            const offset = this.page * this.limit;

            this.$apollo.queries.comments.fetchMore({
                variables: {
                    ownerId: this.elementId,
                    limit: this.limit,
                    offset,
                },

                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const comments = [...(previousResult?.comments ?? []), ...fetchMoreResult.comments];

                    this.$nextTick(() => {
                        this.noMore = fetchMoreResult.comments.length < this.limit;
                    });

                    return {
                        comments,
                    };
                },
            });
        },

        scrollToRecentComment() {
            if (this.$refs.comments) {
                const $lastChild = this.$refs.comments.querySelector('.comment:last-child');

                if ($lastChild) {
                    $lastChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            }
        },

        onNewComment() {
            this.scrollToRecentComment();
        },
    },
};

</script>
